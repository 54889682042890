import NoteAddIcon from '@mui/icons-material/NoteAdd';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {ReactNode} from "react";
import ErrorIcon from '@mui/icons-material/Error';

export type ERROR_TYPE =
    | "INVALID_PATIENT_FORM"
    | "???"
    ;

export type MACHINE_STATE =
    | "PATIENT_SELECTION"
    | "PATIENT_CREATION"
    | "CONSULTATION"
    | "RECAP"
    | "ERROR"
    ;

export const MACHINE_STATE_DISPLAY: { [key in MACHINE_STATE]: { title: string, icon: ReactNode } } = {
    "PATIENT_SELECTION": {
        title: "Patient Selection",
        icon: <HighlightAltIcon/>
    },
    "PATIENT_CREATION": {
        title: "Patient Creation",
        icon: <NoteAddIcon/>
    },
    "CONSULTATION": {
        title: "Consultation",
        icon: <LocalHospitalIcon/>
    },
    "RECAP": {
        title: "Recap",
        icon: <ReceiptIcon/>
    },
    "ERROR": {
        title: "Error",
        icon: <ErrorIcon/>
    },
}

export type MACHINE_ACTION =
    | "GET_RESUME_DATA"
    | "CREATE_PATIENT"
    | "CREATE_PATIENT_PROMPT"
    | "CONFIRM_CREATE_PATIENT"
    | "RESUME_CONSULTATION"
    | "RESUME_RECAP"
    | "SELECT_PATIENT_START_CONSULTATION"
    | "CONFIRM_CREATE_PATIENT_START_CONSULTATION"
    | "CONSULTATION_PROMPT"
    | "FINISH_CONSULTATION_START_RECAP"
    | "RECAP_EDIT_PROMPT"
    | "FINISH_RECAP"
    | "TEST"
    ;

export type CONSULTATION_COMMAND_TYPE =
    | "MEDICAL_CHECKS"
    | "DIAGNOSES"
    | "PROCEDURES"
    | "MATERIALS"
    | "METHODOLOGY"
    | "Q_AND_A"
    | "FINISH_CONSULTATION"
    ;

export const CONSULTATION_TAB_NAMES: { [key in CONSULTATION_COMMAND_TYPE]: string } = {
    MEDICAL_CHECKS: "Medical checks",
    DIAGNOSES: "Diagnoses",
    PROCEDURES: "Procedures",
    MATERIALS: "Materials",
    METHODOLOGY: "Methodology",
    Q_AND_A: "Questions",
    FINISH_CONSULTATION: "FINISH_CONSULTATION",
}

export type CONSULTATION_LEFT_PANEL =
    | "PATIENT_SUMMARY"
    | "NOTES"
    ;

export type TRANSCRIPTION_STATUS =
    | "START"
    | "IN_PROGRESS"
    | "STOP"
    | "NO_OP"
    | "ERROR"

export type STOCK_SOUND =
    | "TRANSCRIBE_START"
    | "TRANSCRIBE_NO_OP"
    | "TRANSCRIBE_USER_STOP"
    | "TRANSCRIBE_END"
    | "TRANSCRIBE_ERROR"

export type MachineRequest = {
    initialState: MACHINE_STATE,
    action: MACHINE_ACTION,
    expectedNewState: MACHINE_STATE,
    requestPayload: any
}

export type MachineResponse = {
    request: MachineRequest,
    effectiveNewState: MACHINE_STATE,
    responsePayload: any
}

export type Patient = {
    ID: number,
    FullName?: string,
    Gender?: "Male" | "Female",
    MedicalConditions?: string[],
    IsCompleted: boolean,
    ThreadId: number,
    Age?: number,
    Summary?: string
}

export type PatientCreationResponse = {
    patient: Patient,
    finishCreation: boolean,
    startConsultation: boolean
}

export type Consultation = {
    ID: number,
    PatientId: number,
    Start: string, // datetime
    End?: string, // datetime
    MainThreadId: number,
    Summary?: string,
    CommandTypes: CONSULTATION_COMMAND_TYPE[]
}

export type ActionLog = {
    ID: number,
    InitialState: MACHINE_STATE,
    Action: MACHINE_ACTION,
    ExpectedNewState: MACHINE_STATE,
    RequestPayloadJson: string,
    RequestTimestamp: string, // datetime
    EffectiveNewState: MACHINE_STATE,
    ResponsePayloadJson: string,
    ResponseTimestamp: string, // datetime
    ConsultationId?: number
}

export type ConsultationPromptResponsePayload = {
    probabilitiesAndNotes: ConsultationCommandProbabilitiesAndNotes,
    responses: {[key in CONSULTATION_COMMAND_TYPE]: any}, // value is the corresponding ConsultationResponseCommandDataStructure
    starredType?: CONSULTATION_COMMAND_TYPE,
    finishConsultation: boolean
}

export type ConsultationCommandProbabilitiesAndNotes = {
    probabilities: {[key: string]: number}, // key is CONSULTATION_COMMAND_TYPE
    finishConsultationProbability: number,
    notesBulletPoint: string
}

export type SelectPatientStartConsultationResponsePayload = {
    patient: Patient,
    consultation: Consultation,
    logs: ActionLog[]
}

export type Recap = {
    ID: number,
    ConsultationId: number,
    Content?: string, // json
    IsConfirmed: boolean
}

export type RecapResponsePayload = {
    recap: Recap,
    consultation: Consultation,
    patient: Patient,
    defaultEmails: {
        Field: string,
        Values: string[]
    }
}

export type RecapContent = {
    patient: Patient,
    consultation: Consultation,
    reasonForConsultation: string,
    consultationSummary: string,
    medicationsAndRecommendations: string[],
    nextConsultation: string
}

export type TranscribeResponseType =
    | "TRANSCRIPTION"
    | "WAKE_WORD"
    ;

export type TranscriptionResponse = {
    type: TranscribeResponseType,
    status: TRANSCRIPTION_STATUS,
    transcription: string,
}

export type WakeWordResponse = {
    type: TranscribeResponseType,
    detectedWakeWords: WakeWord[],
}

export type WakeWord =
    | "CHERRY"
    | "CONFIRM"
    | "FINISH"
    | "CREATE"
    | "CANCEL"

    | "PATIENT_SUMMARY"
    | "NOTES"
    | "MEDICAL_CHECKS"
    | "DIAGNOSES"
    | "PROCEDURES"
    | "METHODOLOGY"
    | "Q_AND_A"
    ;

export type SpeakerEventType =
    | "PREPARING"
    | "DONE"
    | "ERROR"
    ;

export type SpeakerEvent = {
    type: SpeakerEventType
}

export type DialogPayload = {
    isOpen?: boolean,
    title: string,
    content: string,
    lines?: number,
    onConfirm: any,
    onCancel?: any
}

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Button, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {stateTransitionRequest} from "../../../redux/slices/stateMachineSlice";
import {createNewPatient} from "../../../helpers/patientSelectionHelper";
import palette, {rgba} from "../../../helpers/palette";
import fonts from "../../../helpers/fonts";

export default function () {
    const dispatch = useDispatch();

    return (
        <Grid container style={{
            height: "10vh",
            background: "linear-gradient(45deg, " + rgba.primary + " 0%," + rgba.primary + " 7.5%)",
        }}>
            <Grid item xs={8}>
                <div>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                width: 40,
                                height: 40,
                                margin: 20,
                                color: palette.primary,
                                backgroundColor: palette.background
                            }}>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Select a patient"
                            secondary="to start a consultation"
                            primaryTypographyProps={{fontSize: 25, fontFamily: fonts.secondary, color: palette.background3}}
                            secondaryTypographyProps={{fontSize: 18, fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </div>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={3} style={{display: "flex", alignItems: "center", justifyContent: "right", paddingRight: 20}}>
                <Card style={{borderRadius: 999, paddingRight: 20}} onClick={() => createNewPatient(dispatch)}>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                height: 40,
                                color: palette.supporting,
                                backgroundColor: palette.background
                            }}>
                                <PersonAddAlt1Icon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Create new patient"
                            primaryTypographyProps={{style:{fontFamily: fonts.secondary, color: palette.background3}}}
                        />
                    </ListItem>
                </Card>
            </Grid>
        </Grid>
    )
        ;
}

import {configureStore} from '@reduxjs/toolkit';
import stateMachineSlice from "./slices/stateMachineSlice";
import audioSlice from "./slices/audioSlice";
import consultationSlice from "./slices/consultationSlice";
import patientSelectionSlice from "./slices/patientSelectionSlice";
import patientCreationSlice from "./slices/patientCreationSlice";
import recapSlice from "./slices/recapSlice";
import confirmDialogBoxSlice from "./slices/confirmDialogBoxSlice";
import textDialogBoxSlice from "./slices/textDialogBoxSlice";

const asyncDispatchMiddleware = (store: any) => (next: any) => (action: any) => {
    let syncActivityFinished = false;
    let actionQueue: any[] = [];

    function flushQueue() {
        actionQueue.forEach(a => setTimeout(() => store.dispatch(a), 0));
        actionQueue = [];
    }

    function asyncDispatch(asyncAction: any) {
        actionQueue = actionQueue.concat([asyncAction]);
        if (syncActivityFinished) flushQueue();
    }

    const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });
    const res = next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();
    return res;
};

export const store = configureStore({
    reducer: {
        stateMachineSlice,
        consultationSlice,
        patientSelectionSlice,
        patientCreationSlice,
        recapSlice,
        audioSlice,
        confirmDialogBoxSlice,
        textDialogBoxSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat(asyncDispatchMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {
    Avatar,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import EmptyCommandPlaceholder from "./EmptyCommandPlaceholder";
import palette from "../../../../../helpers/palette";
import MasksIcon from '@mui/icons-material/Masks';
import Icd10Chip from "../../../../../components/Icd10Chip";

export default function () {
    const materials = useSelector((state: RootState) => state.consultationSlice.commandResponses["MATERIALS"]);

    if (!materials) return <EmptyCommandPlaceholder/>;

    const materialsList = materials.analysis.materialsForMostRelevantProcedure.materials;
    const procedureName = materials.analysis.materialsForMostRelevantProcedure.procedure.name;
    const icd10 = materials.analysis.materialsForMostRelevantProcedure.diagnosis.icd10;
    const diagnosisName = materials.analysis.materialsForMostRelevantProcedure.diagnosis.diagnosisName;

    return (
        <List style={{padding: 0}}>
            <ListItemText
                style={{padding: 10}}
                key={procedureName}
                primary={procedureName}
                secondary={
                    <span>
                        <Icd10Chip icd10={icd10}/> {diagnosisName}
                    </span>}
            />
            {materialsList.map((step: string) =>
                <span key={step}>
                    <Divider/>
                    <ListItem>
                        <ListItemAvatar style={{marginRight: -20}}>
                            <Avatar style={{backgroundColor: palette.supporting2}} sx={{ width: 24, height: 24 }}>
                                <MasksIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText key={step} primary={step}/>
                    </ListItem>
                </span>
            )}
        </List>
    )
}
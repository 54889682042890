import {stateTransitionRequest} from "../redux/slices/stateMachineSlice";

export function createNewPatient(dispatch: any) {
    dispatch(stateTransitionRequest({
        initialState: "PATIENT_SELECTION",
        action: "CREATE_PATIENT",
        expectedNewState: "PATIENT_CREATION",
        requestPayload: {}
    }));
}

export function patientSelectionResumeConsultation(dispatch: any) {
    dispatch(stateTransitionRequest({
        initialState: "CONSULTATION",
        action: "RESUME_CONSULTATION",
        expectedNewState: "CONSULTATION",
        requestPayload: {}
    }));
}

export function patientSelectionResumeRecap(dispatch: any) {
    dispatch(stateTransitionRequest({
        initialState: "RECAP",
        action: "RESUME_RECAP",
        expectedNewState: "RECAP",
        requestPayload: {}
    }));
}
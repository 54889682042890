import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Card from "@mui/material/Card";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {Tab} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import {RootState} from "../../../../redux/store";
import {setRightConsultationTab} from "../../../../redux/slices/consultationSlice";
import {CONSULTATION_COMMAND_TYPE, CONSULTATION_TAB_NAMES} from "../../../../entities/types";
import palette from "../../../../helpers/palette";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import fonts from "../../../../helpers/fonts";
import MedicalChecks from "./commandComponents/MedicalChecks";
import Diagnoses from "./commandComponents/Diagnoses";
import Procedures from "./commandComponents/Procedures";
import heights from "../../../../helpers/heights";
import Materials from "./commandComponents/Materials";
import Methodology from "./commandComponents/Methodology";
import Questions from "./commandComponents/Questions";

const height = "70vh";

export default function () {
    const dispatch = useDispatch();
    const consultation = useSelector((state: RootState) => state.consultationSlice);
    const activePanel = consultation.rightActivePanel || consultation.consultationCommands[0];

    function getCommandComponent(command: CONSULTATION_COMMAND_TYPE) {
        switch (command) {
            case "MEDICAL_CHECKS":
                return <MedicalChecks/>
            case "DIAGNOSES":
                return <Diagnoses/>
            case "PROCEDURES":
                return <Procedures/>
            case "MATERIALS":
                return <Materials/>
            case "METHODOLOGY":
                return <Methodology/>
            case "Q_AND_A":
                return <Questions/>
        }
    }

    return (
        <Card style={{height}}>
            <TabContext value={activePanel}>
                <TabList
                    onChange={(e: any, i: string) => dispatch(setRightConsultationTab(i))}
                    scrollButtons
                    variant="scrollable"
                    allowScrollButtonsMobile
                    style={{backgroundColor: palette.background2}}
                >
                    {
                        consultation.consultationCommands.map(command =>
                            <Tab
                                key={command}
                                value={command}
                                label={(consultation.unreadRightPanels.includes(command) ? "🔴 " : "") + CONSULTATION_TAB_NAMES[command]}
                                style={{
                                    backgroundColor: activePanel === command ? palette.primary : palette.background2,
                                    fontFamily: fonts.secondary,
                                    color: palette.background3
                            }}
                            />)
                    }
                </TabList>
                {
                    consultation.consultationCommands.map(command =>
                        <TabPanel key={command} value={command} style={{padding: 0, overflow: "auto", height: heights.consultationPanel}}>
                            {
                                getCommandComponent(command)
                            }
                        </TabPanel>)
                }
            </TabContext>
        </Card>
    )
}

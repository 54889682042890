import {createTheme} from "@mui/material";
import palette from "./helpers/palette";
import fonts from "./helpers/fonts";

export default createTheme({
    typography: {
        fontFamily: fonts.primary
    },
    palette: {
        mode: "light",
        primary: {
            main: palette.primary,
        },
        secondary: {
            main: palette.supporting,
        },
        success: {
            main: palette.supporting3,
        }
    },
});
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Card from "@mui/material/Card";
import ConsultationNotes from "./ConsultationNotes";
import {AppBar, Tab, Tabs} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {RootState} from "../../../../redux/store";
import {setLeftConsultationTab} from "../../../../redux/slices/consultationSlice";
import ConsultationPatientSummary from "./ConsultationPatientSummary";
import palette from "../../../../helpers/palette";
import fonts from "../../../../helpers/fonts";

const height = "70vh";

export default function () {
    const dispatch = useDispatch();
    const leftActivePanel = useSelector((state: RootState) => state.consultationSlice.leftActivePanel);

    return (
        <Card style={{height}}>
            <TabContext value={leftActivePanel}>
                <TabList onChange={(e: any, i: string) => dispatch(setLeftConsultationTab(i))}
                         style={{backgroundColor: palette.background2}}>
                    <Tab value="PATIENT_SUMMARY" label="Patient Summary"
                         style={{
                             backgroundColor: leftActivePanel === "PATIENT_SUMMARY" ? palette.primary : palette.background2,
                             fontFamily: fonts.secondary,
                             color: palette.background3
                    }}
                    />
                    <Tab value="NOTES" label="Notes"
                         style={{
                             backgroundColor: leftActivePanel === "NOTES" ? palette.primary : palette.background2,
                             fontFamily: fonts.secondary,
                             color: palette.background3
                         }}
                    />
                </TabList>
                <TabPanel value="PATIENT_SUMMARY" style={{padding: 0}}>
                    <ConsultationPatientSummary/>
                </TabPanel>
                <TabPanel value="NOTES" style={{padding: 5}}>
                    <ConsultationNotes/>
                </TabPanel>
            </TabContext>
        </Card>
    )
}
import React, {useEffect, useState} from 'react';
import {CssBaseline, Grid, ThemeProvider} from "@mui/material";
import {Provider, useDispatch, useSelector} from "react-redux";
import {RootState, store} from "./redux/store";
import materialUiTheme from "./theme";
import App from "./App";

export default function () {
    return (
        <Provider store={store}>
            <ThemeProvider theme={materialUiTheme}>
                <CssBaseline enableColorScheme/>
                <App />
            </ThemeProvider>
        </Provider>
    )
        ;
}

import {Patient} from "../entities/types";
import {setConfirmDialogBox} from "../redux/slices/confirmDialogBoxSlice";
import {stateTransitionRequest} from "../redux/slices/stateMachineSlice";

export function isValidPatient(patient: Patient) {
    if (!patient.FullName || !patient.Age || patient.Age <= 0) {
        alert("Invalid patient");
        return false;
    }
    return true;
}

export function dialog_confirmCreatePatient(dispatch: any, patient: Patient) {
    if (isValidPatient(patient))
        dispatch(setConfirmDialogBox({
            title: "Create patient",
            content: "Are you sure you wish to create this patient?", // todo: display the patient info?
            onConfirm: () => stateTransitionRequest({
                initialState: "PATIENT_SELECTION",
                action: "CONFIRM_CREATE_PATIENT",
                expectedNewState: "PATIENT_SELECTION",
                requestPayload: {patient}
            })
        }));
}

export function dialog_confirmCreatePatientStartConsultation(dispatch: any, patient: Patient) {
    if (isValidPatient(patient))
        dispatch(setConfirmDialogBox({
            title: "Create patient and start consultation",
            content: "Are you sure you wish to create this patient and start a consultation?", // todo: display the patient info?
            onConfirm: () => stateTransitionRequest({
                initialState: "CONSULTATION",
                action: "CONFIRM_CREATE_PATIENT_START_CONSULTATION",
                expectedNewState: "CONSULTATION",
                requestPayload: {patient}
            })
        }));
}

export function cancelCreatePatient(dispatch: any) {
    dispatch(stateTransitionRequest({
        initialState: "PATIENT_SELECTION",
        action: "GET_RESUME_DATA",
        expectedNewState: "PATIENT_SELECTION",
        requestPayload: {}
    }));
}


import React, {ReactNode, useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import {Avatar, Backdrop} from "@mui/material";
import {useSelector} from "react-redux";
import logo from "../../../icons/Cherry-Dentist.png";
import {RootState} from "../../../redux/store";
import {centerChildren} from "../../../helpers/inlineCssHelpers";
import PropagateLoader from "react-spinners/PropagateLoader";
import palette from "../../../helpers/palette";
import fonts from "../../../helpers/fonts";

export default function () {
    const [cherryDisplay, setCherryDisplay] = useState<ReactNode>(<div/>);
    const [responsePrompt, setResponsePrompt] = useState<string | undefined>();

    const isTranscribeRecording = useSelector((state: RootState) => state.audioSlice.isTranscribeRecording);
    const transcriptionResponse = useSelector((state: RootState) => state.audioSlice.transcriptionResponse);
    const isAwaitingResponse = useSelector((state: RootState) => state.stateMachineSlice.isAwaitingResponse);

    const machineState = useSelector((state: RootState) => state.stateMachineSlice.machineState);
    const request = useSelector((state: RootState) => state.stateMachineSlice.request);
    const consultationResponsePrompt = useSelector((state: RootState) => state.consultationSlice.responsePrompt);


    useEffect(() => {
        setResponsePrompt(getResponsePrompt());
    }, [machineState, isTranscribeRecording, transcriptionResponse, isAwaitingResponse, consultationResponsePrompt]);

    useEffect(() => {
        setCherryDisplay(getCherryDisplay());
    }, [machineState, isTranscribeRecording, transcriptionResponse, isAwaitingResponse, consultationResponsePrompt, responsePrompt]);

    function getResponsePrompt() {
        switch (machineState) {
            case "PATIENT_SELECTION":
                break;
            case "PATIENT_CREATION":
                break;
            case "CONSULTATION":
                return consultationResponsePrompt;
            case "RECAP":
                break;
            case "ERROR":
                return "Error...";
        }
    }

    function getCherryDisplay() {
        if (isTranscribeRecording)
            return <div style={{textAlign: "center"}}>
                🍒
                <div
                    style={{fontFamily: fonts.secondary, color: palette.background3}}
                >
                    Cherry is listening...
                </div>
            </div>;

        if (isAwaitingResponse && request?.action.includes("PROMPT"))
            return <PropagateLoader color={palette.primary}/>

        if (responsePrompt)
            return <div style={responsePrompt.length > 250 ? {height: "14vh"} : {...centerChildren}}>
                {responsePrompt}
        </div>;

        return <div style={{
            textAlign: "center",
        }}>
            🍒
            <div
                style={{fontFamily: fonts.secondary, color: palette.background3}}
            >
                Cherry's answers will display here
            </div>
        </div>
    }


    return (<div style={{position: "relative", display: "flex", justifyContent: "flex-start"}}>
            <Avatar
                style={{position: "absolute", right: 0, top: "50%", translate: "0% -50%", width: 60, height: 60, zIndex: 0}}
            >
                <img
                    src={logo}
                    alt="Icon"
                    style={{width: 75, height: 75}}
                />
            </Avatar>
            <Card style={{
                width: "95%",
                height: "14vh",
                padding: 20,
                paddingRight: "5%",
                overflow: "auto",
                ...centerChildren,
            }}>
                <span>
                    {cherryDisplay}
                </span>
            </Card>
        </div>
    );
}

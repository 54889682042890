import React from 'react';
import oryxLogo from "../../../icons/oryxLogo/palette-no-bg.png";
import {Box} from "@mui/material";
import ChatButtons from "../chat/ChatButtons";

export default function () {
    return (
        <Box style={{width: "100%", textAlign: "center"}}>
            <ChatButtons/>
            <br/>
            <br/>
            <img
                src={oryxLogo}
                style={{width: 150}}
            />
        </Box>
    )
};

import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {
    Avatar, Typography,
} from "@mui/material";
import EmptyCommandPlaceholder from "./EmptyCommandPlaceholder";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import palette from "../../../../../helpers/palette";

export default function () {
    const questions = useSelector((state: RootState) => state.consultationSlice.commandResponses["Q_AND_A"]);

    if (!questions) return <EmptyCommandPlaceholder/>;

    const answer = questions.analysis.answerToUserQuestion;

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', padding: 30, height: "90%"}}>
                <Avatar style={{backgroundColor: palette.supporting2}}>
                    <TipsAndUpdatesIcon/>
                </Avatar>
                <Typography variant="h6" style={{marginLeft: 15}}>
                    {answer}
                </Typography>
            </div>
        </>
    )
}
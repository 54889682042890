import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {forwardIsAwaitingResponse, forwardMachineState, sendTranscribeChunk} from "../redux/slices/audioSlice";
// @ts-ignore
import transcribeStartSound from "../audioFiles/notification-beep-229154.mp3";
// @ts-ignore
import transcribeNoOpSound from "../audioFiles/short-punchy-sine-wave-ding-4-d-211755.mp3";
// @ts-ignore
import transcribeUserStopSound from "../audioFiles/short-punchy-sine-wave-ding-4-d-211755.mp3";
// @ts-ignore
import transcribeEndSound from "../audioFiles/blip-131856.mp3";
import useSound from "use-sound";
import {stateMachineSlice} from "../redux/slices/stateMachineSlice";

export default function () {
    const dispatch = useDispatch();
    const audioContextRef = useRef<AudioContext>();
    const mediaStreamRef = useRef<MediaStream>();
    const stockSound = useSelector((state: RootState) => state.audioSlice.stockSound);
    const isAwaitingResponse = useSelector((state: RootState) => state.stateMachineSlice.isAwaitingResponse);
    const isAwaitingAudio = useSelector((state: RootState) => state.stateMachineSlice.isAwaitingAudio); // todo: do the opposite and only play the audio if transcription is not running? (easier and maybe better)
    const machineState = useSelector((state: RootState) => state.stateMachineSlice.machineState);
    const isTranscribeMuted = useSelector((state: RootState) => state.audioSlice.isTranscribeMuted);
    const [playTranscribeStart] = useSound(transcribeStartSound);
    const [playTranscribeNoOp] = useSound(transcribeNoOpSound);
    const [playTranscribeUserStop] = useSound(transcribeUserStopSound);
    const [playTranscribeEnd] = useSound(transcribeEndSound);

    const startRecording = async () => {
        const stream: MediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
        mediaStreamRef.current = stream;
        audioContextRef.current = new window.AudioContext({sampleRate: 16000});
        await audioContextRef.current.audioWorklet.addModule('AudioProcessor.js');
        const source = audioContextRef.current.createMediaStreamSource(stream);
        const audioWorkletNode = new AudioWorkletNode(audioContextRef.current, 'AudioWorklet');
        audioWorkletNode.port.onmessage = (event) => {
            dispatch(sendTranscribeChunk(event.data));
        };
        source.connect(audioWorkletNode);
    };

    const stopRecording = () => {
        if (audioContextRef.current)
            audioContextRef.current.close().then(() => {
                audioContextRef.current = undefined;
            });
        const tracks = mediaStreamRef.current?.getTracks();
        if (tracks)
            tracks.forEach((track: any) => track.stop());
        mediaStreamRef.current = undefined;
    };

    useEffect(() => {
        isTranscribeMuted ? stopRecording() : startRecording();
    }, [isTranscribeMuted]);

    useEffect(() => {
        if (!stockSound) return;
        switch (stockSound) {
            case "TRANSCRIBE_START":
                return playTranscribeStart();
            case "TRANSCRIBE_NO_OP":
                return playTranscribeNoOp();
            case "TRANSCRIBE_USER_STOP":
                return playTranscribeUserStop();
            case "TRANSCRIBE_END":
                return playTranscribeEnd();
            case "TRANSCRIBE_ERROR":
                return playTranscribeNoOp();
        }
    }, [stockSound]);

    useEffect(() => {
        dispatch(forwardIsAwaitingResponse(isAwaitingResponse));
    }, [isAwaitingResponse]);

    useEffect(() => {
        dispatch(forwardMachineState(machineState));
    }, [machineState]);

    return (<>
        {/*Transcribe: {transcribeResponse?.transcription}*/}
    </>);
};
import React from 'react';
import {Button, Fab, Grid} from "@mui/material";
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import MicOffIcon from '@mui/icons-material/MicOff';
import fonts from "../../../helpers/fonts";
import palette from "../../../helpers/palette";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import {
    forceCancelTranscription, forceStopTranscription,
    setIsSpeakerMuted,
    setIsTranscribeMuted, startTranscription,
    stopSpeaker
} from "../../../redux/slices/audioSlice";
import HearingIcon from '@mui/icons-material/Hearing';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import MessageIcon from '@mui/icons-material/Message';
import {setTextDialogBox} from "../../../redux/slices/textDialogBoxSlice";
import {statePrompt} from "../../../redux/slices/stateMachineSlice";

export default function () {
    const dispatch = useDispatch();
    const machineState = useSelector((state: RootState) => state.stateMachineSlice.machineState);
    const isTranscribeRecording = useSelector((state: RootState) => state.audioSlice.isTranscribeRecording);
    const isSpeakerMuted = useSelector((state: RootState) => state.audioSlice.isSpeakerMuted);
    const isTranscribeMuted = useSelector((state: RootState) => state.audioSlice.isTranscribeMuted);

    const MuteMicrophoneComponent = <Fab
        style={{
            color: isTranscribeMuted ? palette.primary : palette.background3,
            backgroundColor: isTranscribeMuted ? palette.background3 : palette.primary,
            zIndex: 0
        }}
        onClick={() => dispatch(setIsTranscribeMuted(!isTranscribeMuted))}
        size="small"
    >
        {isTranscribeMuted ? <MicOffIcon/> : <KeyboardVoiceIcon/>}
    </Fab>;

    if (machineState === "PATIENT_SELECTION")
        return MuteMicrophoneComponent;

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {isTranscribeRecording ? (
                <>
                    <Button
                        variant="contained"
                        startIcon={<SendIcon/>}
                        onClick={() => dispatch(forceStopTranscription({}))}
                        style={{
                            marginBottom: 20,
                            fontFamily: fonts.secondary,
                            color: palette.background3,
                            width: 150,
                            backgroundColor: palette.accent
                        }}>
                        Send
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<CancelScheduleSendIcon/>}
                        onClick={() => dispatch(forceCancelTranscription({}))}
                        style={{
                            fontFamily: fonts.secondary,
                            color: palette.background3,
                            width: 150,
                            backgroundColor: palette.accent2
                        }}>
                        Cancel
                    </Button>
                </>
            ) : (
                <Grid container style={{textAlign: "center"}} spacing={3}>
                    <Grid item xs={6}>
                        <Fab
                            style={{
                                color: palette.background3,
                                backgroundColor: palette.primary,
                                zIndex: 0
                            }}
                            onClick={() => dispatch(startTranscription({}))}
                            size="small"
                        >
                            <RecordVoiceOverIcon/>
                        </Fab>
                    </Grid>
                    <Grid item xs={6}>
                        {MuteMicrophoneComponent}
                    </Grid>
                    <Grid item xs={6}>
                        <Fab
                            style={{
                                color: palette.background3,
                                backgroundColor: palette.primary,
                                zIndex: 0
                            }}
                            onClick={() => dispatch(setTextDialogBox({
                                isOpen: true,
                                title: "Make a text prompt to Cherry",
                                lines: 3,
                                content: "",
                                onConfirm: (prompt: string) => statePrompt(prompt)
                            }))}
                            size="small"
                        >
                            <MessageIcon/>
                        </Fab>
                    </Grid>
                    <Grid item xs={6}>
                        <Fab
                            style={{
                                color: isSpeakerMuted ? palette.primary : palette.background3,
                                backgroundColor: isSpeakerMuted ? palette.background3 : palette.primary,
                                zIndex: 0
                            }}
                            onClick={() => {
                                dispatch(setIsSpeakerMuted(!isSpeakerMuted))
                                dispatch(stopSpeaker({}))
                            }}
                            size="small"
                        >
                            {isSpeakerMuted ? <HearingDisabledIcon/> : <HearingIcon/>}
                        </Fab>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";

const height = "62vh";

export default function () {
    const patientSummary = useSelector((state: RootState) => state.consultationSlice.patientSummary);

    return (
        <div style={{overflow: "auto", height}}>
            <ul style={{textAlign: "justify", padding: "inherit"}}>
                <div style={{textAlign: "justify"}} dangerouslySetInnerHTML={{__html: formatSummary(patientSummary)}}/>
            </ul>
        </div>
    )
}

function formatSummary(summary?: string): string {
    if (!summary) return '<></>';
    summary = summary.replace(/\*\*(.*?)\*\*:/g, '<h2>$1</h2>')
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/- (.*?)(\r?\n|$)/g, '<li>$1</li>')
        .replace(/\d+\.\s(.*?)(\r?\n|$)/g, '<li>$1</li>')
        .replace(/(<li>.*<\/li>)(?!(<li>))/g, '<ul>$1</ul>')
        .replace(/<li>(.*?)<\/li>(?!(<li>|\d+\.))/g, '<ol><li>$1</li></ol>')
        .replace(/\n\n/g, '</p><p>');
    return '<p>' + summary + '</p>';
}
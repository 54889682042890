import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DialogPayload} from "../../entities/types";
import {clearWakeWords} from "./audioSlice";
import {onConfirmTextDialogAction} from "./sharedActions";

interface TextDialogBoxState extends DialogPayload {

}

const initialState: TextDialogBoxState = {
    isOpen: false,
    title: "",
    content: "",
    lines: 1,
    onConfirm: () => {},
    onCancel:  () => {}
};

export const textDialogBoxSlice = createSlice({
    name: 'textDialogBoxSlice',
    initialState,
    reducers: {
        setTextDialogBox: (state: TextDialogBoxState, action: PayloadAction<DialogPayload>) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.content = action.payload.content;
            state.lines = action.payload.lines;
            state.onConfirm = action.payload.onConfirm;
            state.onCancel = action.payload.onCancel;
        },
        setTextDialogBoxContent: (state: TextDialogBoxState, action: PayloadAction<string>) => {
            state.content = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(onConfirmTextDialogAction, (state, action) => {
            if (!state.isOpen) return;
            state.isOpen = false;
            const dispatchSupplier = action.payload ? state.onConfirm : state.onCancel;
            if (!dispatchSupplier) return;
            (<any>action).asyncDispatch(dispatchSupplier(action.payload));
        });
    },
});

export const {setTextDialogBox, setTextDialogBoxContent} = textDialogBoxSlice.actions;

export default textDialogBoxSlice.reducer;

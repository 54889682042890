import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DialogPayload} from "../../entities/types";
import {clearWakeWords} from "./audioSlice";
import {onConfirmBooleanDialogAction} from "./sharedActions";

interface ConfirmDialogBoxState extends DialogPayload {

}

const initialState: ConfirmDialogBoxState = {
    isOpen: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onCancel:  () => {}
};

export const confirmDialogBoxSlice = createSlice({
    name: 'confirmDialogBoxSlice',
    initialState,
    reducers: {
        setConfirmDialogBox: (state: ConfirmDialogBoxState, action: PayloadAction<DialogPayload>) => {
            state.isOpen = true;
            state.title = action.payload.title;
            state.content = action.payload.content;
            state.onConfirm = action.payload.onConfirm;
            state.onCancel = action.payload.onCancel;
        },
    },
    extraReducers: builder => {
        builder.addCase(onConfirmBooleanDialogAction, (state, action) => {
            if (!state.isOpen) return;
            state.isOpen = false;
            const dispatchSupplier = action.payload ? state.onConfirm : state.onCancel;
            if (!dispatchSupplier) return;
            (<any>action).asyncDispatch(dispatchSupplier());
        });
    },
});

export const {setConfirmDialogBox} = confirmDialogBoxSlice.actions;

export default confirmDialogBoxSlice.reducer;

import React from "react";
import {Avatar, Chip} from "@mui/material";
import palette from "../helpers/palette";

export default function (props: { icd10: string }) {
    return <Chip
        avatar={<Avatar component={"span"} style={{backgroundColor: palette.supporting2, color: palette.background}}>ICD</Avatar>}
        label={props.icd10}
        component={"span"}
        style={{marginRight: 5}}
    />
}
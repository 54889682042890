import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {Backdrop, CardContent, Grid} from "@mui/material";
import Card from "@mui/material/Card";
import {PulseLoader} from "react-spinners";
import palette from "../helpers/palette";


export default function () {
    const isSocketConnected = useSelector((state: RootState) => state.stateMachineSlice.isSocketConnected);
    const isSpeakerSocketConnected = useSelector((state: RootState) => state.audioSlice.isSpeakerSocketConnected);
    const isTranscribeSocketConnected = useSelector((state: RootState) => state.audioSlice.isTranscribeSocketConnected);

    return <Backdrop open={!isSocketConnected || !isSpeakerSocketConnected || !isTranscribeSocketConnected}  style={{zIndex: 999}}>
            <Card>
                <CardContent style={{textAlign: "center", padding: 100}}>
                    Connection to cloud systems was lost.
                    <br/>
                    Trying to reconnect...
                    <br/>
                    <br/>
                    <PulseLoader color={palette.primary}/>
                    <br/>
                    If this issue persists, please contact customer support.
                    <br/>
                </CardContent>
            </Card>
    </Backdrop>
        ;
}

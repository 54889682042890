import {setConfirmDialogBox} from "../redux/slices/confirmDialogBoxSlice";
import {stateTransitionRequest} from "../redux/slices/stateMachineSlice";
import {RecapResponsePayload} from "../entities/types";

export function dialog_finishConsultationStartRecap(dispatch: any, consultationId: number) {
    dispatch(setConfirmDialogBox({
        title: "Finish consultation",
        content: "Are you sure you wish to end this consultation?",
        onConfirm: () => stateTransitionRequest({
            initialState: "RECAP",
            action: "FINISH_CONSULTATION_START_RECAP",
            expectedNewState: "RECAP",
            requestPayload: { consultationId }
        })
    }));
}

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {Avatar, Button, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import FastForwardIcon from '@mui/icons-material/FastForward';
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import {dialog_finishConsultationStartRecap} from "../../../helpers/consultationHelper";
import {dialog_confirmCreatePatient} from "../../../helpers/patientCreationHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import palette, {rgba} from "../../../helpers/palette";
import fonts from "../../../helpers/fonts";

export default function () {
    const dispatch = useDispatch();
    const consultationId = useSelector((state: RootState) => state.consultationSlice.consultationId);
    const patientData = useSelector((state: RootState) => state.consultationSlice.patientData);

    return (
        <Grid container style={{
            height: "10vh",
            background: "linear-gradient(45deg, " + rgba.supporting2 + " 0%, " + rgba.primary + " 20%)",
        }}>
            <Grid item xs={8}>
                <div>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                width: 40,
                                height: 40,
                                margin: 20,
                                color: palette.supporting2,
                                backgroundColor: palette.background
                            }}>
                                <LocalHospitalIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Consultation"
                            secondary={"with patient " + patientData?.FullName}
                            primaryTypographyProps={{fontSize: 25, fontFamily: fonts.secondary, color: palette.background3}}
                            secondaryTypographyProps={{fontSize: 18, fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </div>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Card style={{borderRadius: 999, paddingRight: 20}} onClick={() => dialog_finishConsultationStartRecap(dispatch, consultationId)}>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                height: 40,
                                color: palette.accent,
                                backgroundColor: palette.background
                            }}>
                                <CheckCircleIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Finish consultation"
                            primaryTypographyProps={{fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </Card>
            </Grid>
        </Grid>
    )
        ;
}

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {setMachineState} from "../../../redux/slices/stateMachineSlice";
import {dialog_finishRecap} from "../../../helpers/recapHelper";
import RecapBody from "./RecapBody";

export default function () {
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => state.stateMachineSlice.response);
    const recapData = useSelector((state: RootState) => state.recapSlice.recapData);
    const emails = useSelector((state: RootState) => state.recapSlice.emails);
    const wakeWord = useSelector((state: RootState) => state.audioSlice.wakeWord);
    const isDialogOpen = useSelector((state: RootState) => state.confirmDialogBoxSlice.isOpen);

    useEffect(() => {
        if (!response) return;
        if (response.request.initialState === "RECAP")
            switch (response.effectiveNewState) {
                case "PATIENT_SELECTION":
                    dialog_finishRecap(dispatch, recapData, emails);
                    break;
            }
        else switch (response.effectiveNewState) {
            case "PATIENT_SELECTION":
                dispatch(setMachineState("PATIENT_SELECTION"));
                break;
        }
    }, [response]);


    useEffect(() => {
        if (isDialogOpen) return;
        if (wakeWord === "FINISH")
            dialog_finishRecap(dispatch, recapData, emails);
    }, [wakeWord]);


    return (
        <>
            <RecapBody/>
        </>
    )
}

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";

const height = "62vh";

export default function () {
    const bulletPoints = useSelector((state: RootState) => state.consultationSlice.bulletPoints);

    return (
        <div style={{overflow: "auto", height}}>
            <ul style={{paddingRight: 15, textAlign: "justify"}}>
                {
                    bulletPoints.map((bulletPoint, i) => <li key={i}>{bulletPoint}</li>)
                }
            </ul>
        </div>
    )
}
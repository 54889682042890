import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {setupAndConnect, stateTransitionRequest} from "../redux/slices/stateMachineSlice";
import AudioTranscribeRecorder from "./AudioTranscribe";
import {setupAndConnectSpeaker, setupAndConnectTranscribe} from "../redux/slices/audioSlice";
import {Backdrop, Grid} from "@mui/material";
import ReconnectionBackdrop from "./ReconnectionBackdrop";
import DialogBox from './ConfirmDialogBox';
import PropagateLoader from "react-spinners/PropagateLoader";
import palette from "../helpers/palette";
import TextDialogBox from "./TextDialogBox";


export default function () {
    const dispatch = useDispatch();
    const request = useSelector((state: RootState) => state.stateMachineSlice.request);
    const response = useSelector((state: RootState) => state.stateMachineSlice.response);
    const isSocketConnected = useSelector((state: RootState) => state.stateMachineSlice.isSocketConnected);
    const isSpeakerSocketConnected = useSelector((state: RootState) => state.audioSlice.isSpeakerSocketConnected);
    const isTranscribeSocketConnected = useSelector((state: RootState) => state.audioSlice.isTranscribeSocketConnected);
    const isAwaitingResponse = useSelector((state: RootState) => state.stateMachineSlice.isAwaitingResponse);
    const isTranscribeRecording = useSelector((state: RootState) => state.audioSlice.isTranscribeRecording);

    useEffect(() => {
        dispatch(setupAndConnect({}));
        dispatch(setupAndConnectSpeaker({}));
        dispatch(setupAndConnectTranscribe({}));
    }, []);


    useEffect(() => {
        if (isSocketConnected)
            dispatch(stateTransitionRequest({
                initialState: "PATIENT_SELECTION",
                action: "GET_RESUME_DATA",
                expectedNewState: "PATIENT_SELECTION",
                requestPayload: {}
            }));
    }, [isSocketConnected]);

    return <span style={{position: "absolute", top: 0, left: 0}}>
        <Grid container spacing={3}>
            {/*<Grid item xs={3}>*/}
            {/*    Websocket: {isSocketConnected ? "CONNECTED" : "DISCONNECTED"}*/}
            {/*</Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*    Speaker: {isSpeakerSocketConnected ? "CONNECTED" : "DISCONNECTED"}*/}
            {/*    <br/>*/}
            {/*    Transcribe: {isTranscribeSocketConnected ? "CONNECTED" : "DISCONNECTED"}*/}
            {/*</Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*    Response: {isAwaitingResponse ? "AWAITING" : "IDLE"}*/}
            {/*</Grid>*/}
            {/*<Grid item xs={3}>*/}
            {/*    Transcribe: {isTranscribeRecording ? "RECORDING" : "IDLE"}*/}
            {/*</Grid>*/}
        </Grid>
        <br/>
        <AudioTranscribeRecorder/>
        <br/>
        <ReconnectionBackdrop />
        <DialogBox />
        <TextDialogBox />
        {(isAwaitingResponse && !request?.action.includes("PROMPT")) && <Backdrop open={true}><PropagateLoader color={palette.primary}/></Backdrop>}
        {/*{JSON.stringify(response)}*/}
    </span>;
}

import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Avatar, Skeleton} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {centerChildren} from "../../../helpers/inlineCssHelpers";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import palette from "../../../helpers/palette";
import fonts from "../../../helpers/fonts";

const height = "15vh";

export default function () {
    const transcriptionResponse = useSelector((state: RootState) => state.audioSlice.transcriptionResponse);

    return <div style={{position: "relative", display: "flex", justifyContent: "flex-end", height}}>
        <Avatar style={{
            position: "absolute",
            left: 0,
            top: "50%",
            translate: "0% -50%",
            width: 60,
            height: 60,
            backgroundColor: palette.primary
        }}/>
        <Card style={{
            width: "95%",
            height: "14vh",
            padding: 20,
            paddingLeft: "7%",
            overflow: "auto",
            ...centerChildren,
        }}>
                <span>
                {transcriptionResponse
                    ? <div style={(transcriptionResponse.transcription && transcriptionResponse.transcription.length > 250)
                        ? {height: "14vh"}
                        : {...centerChildren}}
                    >{transcriptionResponse.transcription}</div>
                    : <div style={{
                        textAlign: "center",
                    }}>
                        <RecordVoiceOverIcon style={{color: palette.primary}}/>
                        <div style={{fontFamily: fonts.secondary, color: palette.background3}}>
                            Say "Cherry" so she can start listening!
                        </div>
                    </div>
                }
                </span>
        </Card>
    </div>
        ;
}

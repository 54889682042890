import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    ActionLog,
    CONSULTATION_COMMAND_TYPE,
    CONSULTATION_LEFT_PANEL,
    ConsultationPromptResponsePayload, MACHINE_STATE, MachineResponse, Patient,
    SelectPatientStartConsultationResponsePayload
} from "../../entities/types";
import {stateTransitionRequest} from "./stateMachineSlice";

interface consultationState {
    consultationId: number;
    commandResponses: { [type: string]: any }; // string key is -> CONSULTATION_COMMAND
    finishConsultation: boolean;
    bulletPoints: string[];
    consultationCommands: CONSULTATION_COMMAND_TYPE[];
    responsePrompt?: string;
    leftActivePanel: string | CONSULTATION_LEFT_PANEL;
    patientSummary?: string;
    rightActivePanel: string | CONSULTATION_COMMAND_TYPE;
    unreadRightPanels: string[] | CONSULTATION_COMMAND_TYPE[];
    patientData?: Patient;
}

const initialState: consultationState = {
    consultationId: -1,
    commandResponses: {},
    finishConsultation: false,
    bulletPoints: [],
    consultationCommands: [],
    responsePrompt: undefined,
    leftActivePanel: "PATIENT_SUMMARY",
    rightActivePanel: "",
    unreadRightPanels: []
};

export const consultationSlice = createSlice({
    name: 'consultationSlice',
    initialState,
    reducers: {
        pushConsultationResponse: (state: consultationState, action: PayloadAction<MachineResponse>) => {
            handleConsultationResponse(state, action.payload.responsePayload, action.payload.effectiveNewState);
        },
        resumeConsultation: (state: consultationState, action: PayloadAction<SelectPatientStartConsultationResponsePayload>) => {
            handleReset(state, action);
            action.payload.logs.forEach(log => handleConsultationLog(state, log));
        },
        resetConsultation: (state: consultationState, action: PayloadAction<SelectPatientStartConsultationResponsePayload>) => {
            handleReset(state, action);
        },
        setLeftConsultationTab: (state: consultationState, action: PayloadAction<string | CONSULTATION_LEFT_PANEL>) => {
            state.leftActivePanel = action.payload;
        },
        setRightConsultationTab: (state: consultationState, action: PayloadAction<string | CONSULTATION_COMMAND_TYPE>) => {
            state.rightActivePanel = action.payload;
            state.unreadRightPanels = state.unreadRightPanels.filter(type => type !== action.payload);
        },
        consultationPrompt: (state: consultationState, action: PayloadAction<string>) => {
            (<any>action).asyncDispatch(stateTransitionRequest({
                initialState: "CONSULTATION",
                action: "CONSULTATION_PROMPT",
                expectedNewState: "CONSULTATION",
                requestPayload: {
                    consultationId: state.consultationId,
                    prompt: action.payload
                }
            }));
        },
    },
});

export const {
    pushConsultationResponse,
    resumeConsultation,
    resetConsultation,
    setLeftConsultationTab,
    setRightConsultationTab,
    consultationPrompt,
} = consultationSlice.actions;

export default consultationSlice.reducer;

function handleReset(state: consultationState, action: PayloadAction<SelectPatientStartConsultationResponsePayload>) {
    state.patientSummary = action.payload.patient.Summary;
    state.patientData = action.payload.patient;
    state.consultationId = action.payload.consultation.ID;
    state.commandResponses = {};
    state.finishConsultation = false;
    state.bulletPoints = [];
    state.consultationCommands = action.payload.consultation.CommandTypes;
    state.responsePrompt = undefined;
    state.leftActivePanel = "PATIENT_SUMMARY";
    state.rightActivePanel = "";
}

function handleConsultationResponse(state: consultationState, log: ConsultationPromptResponsePayload, effectiveNewState: MACHINE_STATE) {
    if (effectiveNewState === "ERROR") return;
    state.bulletPoints = [...state.bulletPoints, log.probabilitiesAndNotes.notesBulletPoint];
    state.finishConsultation = log.finishConsultation;
    state.leftActivePanel = "NOTES";
    if (log.starredType) {
        state.commandResponses = {...state.commandResponses, ...log.responses};
        state.responsePrompt = log.responses[log.starredType].naturalLanguageSummary;
        state.rightActivePanel = log.starredType;
        state.unreadRightPanels = Object.keys(log.responses);
    }
}

function handleConsultationLog(state: consultationState, actionLog: ActionLog) {
    if (actionLog.Action === "SELECT_PATIENT_START_CONSULTATION" || actionLog.Action === "CONFIRM_CREATE_PATIENT_START_CONSULTATION") {
        const log: SelectPatientStartConsultationResponsePayload = JSON.parse(actionLog.ResponsePayloadJson);
        state.consultationId = log.consultation.ID;
        state.leftActivePanel = "PATIENT_SUMMARY";
        state.patientSummary = log.patient.Summary;
    } else if (actionLog.Action === "CONSULTATION_PROMPT") {
        const log: ConsultationPromptResponsePayload = JSON.parse(actionLog.ResponsePayloadJson);
        handleConsultationResponse(state, log, actionLog.EffectiveNewState);
    } else {
        window.alert("Unhandled action type to update consultation: " + actionLog.Action);
    }
}

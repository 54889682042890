import React from 'react';
import {useSelector} from "react-redux";
import {Grid} from "@mui/material";
import UserChatBox from "./chat/UserChatBox";
import AiChatBox from "./chat/AiChatBox";
import {RootState} from "../../redux/store";
import OryxMedicalAiPlaceholder from './placeholder/OryxMedicalAiPlaceholder';
import ChatButtons from "./chat/ChatButtons";

const height = "15vh";

export default function () {
    const machineState = useSelector((state: RootState) => state.stateMachineSlice.machineState);

    if (machineState === "PATIENT_SELECTION")
        return <OryxMedicalAiPlaceholder />

    return (
        <Grid container spacing={2} style={{paddingLeft: 10, paddingRight: 10, height}} columns={16}>
            <Grid item xs={7}>
                <UserChatBox/>
            </Grid>
            <Grid item xs={2}>
                <ChatButtons/>
            </Grid>
            <Grid item xs={7}>
                <AiChatBox/>
            </Grid>
        </Grid>
    )
        ;
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    MACHINE_ACTION,
    MACHINE_STATE,
    RecapContent, RecapResponsePayload,
} from "../../entities/types";
import {stateTransitionRequest} from "./stateMachineSlice";

interface recapState {
    recapData?: RecapResponsePayload
    content?: RecapContent
    emails?: string[]
    form: {
        emailsDialogOpen: boolean,
    }
}

const initialState: recapState = {
    form: {
        emailsDialogOpen: false,
    }
};

export const recapSlice = createSlice({
    name: 'recapSlice',
    initialState,
    reducers: {
        handleRecapResponse: (state: recapState, action: PayloadAction<{
            RecapResponsePayload: RecapResponsePayload,
            machineAction: MACHINE_ACTION
        }>) => {
            state.recapData = action.payload.RecapResponsePayload;
            state.content = action.payload.RecapResponsePayload.recap.Content
                ? JSON.parse(action.payload.RecapResponsePayload.recap.Content)
                : undefined;
            if (action.payload.machineAction !== "RECAP_EDIT_PROMPT")
                state.emails = action.payload.RecapResponsePayload.defaultEmails.Values
                    ? action.payload.RecapResponsePayload.defaultEmails.Values
                    : undefined;
        },
        resetRecap: (state: recapState, action: PayloadAction<any>) => {
            handleReset(state, action);
        },
        recapEditPrompt: (state: recapState, action: PayloadAction<string>) => {
            (<any>action).asyncDispatch(stateTransitionRequest({
                initialState: "RECAP",
                action: "RECAP_EDIT_PROMPT",
                expectedNewState: "RECAP",
                requestPayload: {
                    recapId: state.recapData?.recap?.ID,
                    prompt: action.payload
                }
            }));
        },
        setEmailsDialogOpen: (state: recapState, action: PayloadAction<boolean>) => {
            state.form.emailsDialogOpen = action.payload;
        },
        addEmail: (state: recapState, action: PayloadAction<string | undefined>) => {
            if (action.payload) {
                if (!isValidEmail(action.payload))
                    alert("Please enter a valid email.")
                else
                    state.emails = [...(state.emails || []), action.payload];
            }
            state.form.emailsDialogOpen = false;
        },
        removeEmail: (state: recapState, action: PayloadAction<string>) => {
            state.emails = state.emails?.filter(mc => mc !== action.payload);
        },
    },
});

export const {
    handleRecapResponse,
    resetRecap,
    recapEditPrompt,
    addEmail,
    removeEmail,
    setEmailsDialogOpen
} = recapSlice.actions;

export default recapSlice.reducer;

function handleReset(state: recapState, action: PayloadAction<any>) {
    state.recapData = undefined;
    state.content = undefined;
}

function isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}



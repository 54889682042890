import React, {useEffect, useState} from 'react';
import {Provider, useDispatch, useSelector} from "react-redux";
import {RootState, store} from "./redux/store";
import GeneralStateComponent from "./components/GeneralStateComponent";
import {MACHINE_STATE} from "./entities/types";
import PatientCreation from "./mainDisplay/././states/patientCreation/PatientCreation";
import PatientSelection from "./mainDisplay/././states/patientSelection/PatientSelection";
import Consultation from "./mainDisplay/././states/consultation/Consultation";
import Recap from "./mainDisplay/././states/recap/Recap";
import Error from "./mainDisplay/././states/error/Error";
import ChatFooter from "./mainDisplay/chatFooter/Footer";
import Header from "./mainDisplay/states/consultation/ConsultationHeader";
import ConsultationHeader from './mainDisplay/states/consultation/ConsultationHeader';
import RecapHeader from "./mainDisplay/states/recap/RecapHeader";
import PatientSelectionHeader from "./mainDisplay/states/patientSelection/PatientSelectionHeader";
import PatientCreationHeader from "./mainDisplay/states/patientCreation/PatientCreationHeader";
import palette from "./helpers/palette";

export default function () {
    const machineState = useSelector((state: RootState) => state.stateMachineSlice.machineState);
    const [stateHeader, setStateHeader] = useState<any>(<div></div>);
    const [stateComponent, setStateComponent] = useState<any>(<div></div>);

    useEffect(() => {
        setStateHeader(getStateHeader(machineState));
        setStateComponent(getStateComponent(machineState));
    }, [machineState]);

    function getStateHeader(machineState: MACHINE_STATE) {
        switch (machineState) {
            case "PATIENT_SELECTION":
                return <PatientSelectionHeader />;
            case "PATIENT_CREATION":
                return <PatientCreationHeader />;
            case "CONSULTATION":
                return <ConsultationHeader />;
            case "RECAP":
                return <RecapHeader />;
            case "ERROR":
                return <div />;
        }
    }

    function getStateComponent(machineState: MACHINE_STATE) {
        switch (machineState) {
            case "PATIENT_SELECTION":
                return <PatientSelection />;
            case "PATIENT_CREATION":
                return <PatientCreation />;
            case "CONSULTATION":
                return <Consultation />;
            case "RECAP":
                return <Recap />;
            case "ERROR":
                return <Error />;
        }
    }

    return (
        <div style={{
            height: '100vh',
            overflow: "hidden",
            backgroundColor: palette.background2,
        }}>
            <div style={{
                height: "10vh",
                position: "relative",
                overflow: 'hidden',
                boxShadow: "0 4px 6px " + palette.background2
            }}>
                {stateHeader}
            </div>
            <div style={{
                height: "75vh",
                //@ts-ignore
                textAlign: "-webkit-center",
                overflow: 'hidden',
                position: "relative",
                padding: 20
            }}>
                {stateComponent}
            </div>
            <div style={{
                height: "15vh",
                position: "relative",
                overflow: 'hidden',
            }}>
                <ChatFooter />
                <div style={{position: "absolute", top: 0, left: 0, width: "100%"}}>
                    <GeneralStateComponent/>
                </div>
            </div>
        </div>
    )
        ;
}

import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Button, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CancelIcon from '@mui/icons-material/Cancel';
import {setMachineState, stateTransitionRequest} from "../../../redux/slices/stateMachineSlice";
import {RootState} from "../../../redux/store";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Patient} from "../../../entities/types";
import {cancelCreatePatient, dialog_confirmCreatePatient, isValidPatient} from "../../../helpers/patientCreationHelper";
import palette, {rgba} from "../../../helpers/palette";
import fonts from "../../../helpers/fonts";

export default function () {
    const dispatch = useDispatch();
    const patient = useSelector((state: RootState) => state.patientCreationSlice.patient);

    return (
        <Grid container style={{
            height: "10vh",
            background: "linear-gradient(45deg, " + rgba.supporting + " 0%, " + rgba.primary + " 20%)",
        }}>
            <Grid item xs={8}>
                <div>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                width: 40,
                                height: 40,
                                margin: 20,
                                color: palette.supporting,
                                backgroundColor: palette.background
                            }}>
                                <PersonAddAlt1Icon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Create new patient"
                            primaryTypographyProps={{fontSize: 25, fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </div>
            </Grid>
            <Grid item xs={2} style={{display: "flex", alignItems: "center", justifyContent: "right", paddingRight: 20}}>
                <Card style={{borderRadius: 999, paddingRight: 20}} onClick={() => dialog_confirmCreatePatient(dispatch, patient)}>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                height: 40,
                                color: palette.accent,
                                backgroundColor: palette.background
                            }}>
                                <CheckCircleIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Finish"
                            primaryTypographyProps={{fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </Card>
            </Grid>
            <Grid item xs={2} style={{display: "flex", alignItems: "center", justifyContent: "right", paddingRight: 20}}>
                <Card style={{borderRadius: 999, paddingRight: 20}} onClick={() => cancelCreatePatient(dispatch)}>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                height: 40,
                                color: palette.accent2,
                                backgroundColor: palette.background
                            }}>
                                <CancelIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Cancel"
                            primaryTypographyProps={{fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </Card>
            </Grid>
        </Grid>
    )
        ;
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    MACHINE_STATE,
    MachineRequest,
    MachineResponse, Patient
} from "../../entities/types";

interface patientSelectionState {
    patients?: Patient[]
}

const initialState: patientSelectionState = {
};

export const patientSelectionSlice = createSlice({
    name: 'patientSelectionSlice',
    initialState,
    reducers: {
        setPatients: (state: patientSelectionState, action: PayloadAction<Patient[]>) => {
            state.patients = action.payload;
        },
    },
});

export const {
    setPatients,
} = patientSelectionSlice.actions;

export default patientSelectionSlice.reducer;

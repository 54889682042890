import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "@mui/material";
import ConsultationRightPanel from "./components/ConsultationRightPanel";
import ConsultationLeftPanel from "./components/ConsultationLeftPanel";
import {RootState} from "../../../redux/store";
import {setMachineState, stateTransitionRequest} from "../../../redux/slices/stateMachineSlice";
import {dialog_finishConsultationStartRecap} from "../../../helpers/consultationHelper";
import {setLeftConsultationTab, setRightConsultationTab} from "../../../redux/slices/consultationSlice";

export default function () {
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => state.stateMachineSlice.response);
    const consultationId = useSelector((state: RootState) => state.consultationSlice.consultationId);
    const wakeWord = useSelector((state: RootState) => state.audioSlice.wakeWord);
    const isDialogOpen = useSelector((state: RootState) => state.confirmDialogBoxSlice.isOpen);

    useEffect(() => {
        if (!response) return;
        if (response.request.initialState === "CONSULTATION")
            switch (response.effectiveNewState) {
                case "RECAP":
                    dialog_finishConsultationStartRecap(dispatch, consultationId);
                    break;
            }
        else switch (response.effectiveNewState) {
            case "RECAP":
                dispatch(setMachineState("RECAP"));
                break;
        }
    }, [response]);

    useEffect(() => {
        if (isDialogOpen || !wakeWord) return;
        else if (wakeWord === "FINISH")
            dialog_finishConsultationStartRecap(dispatch, consultationId);

        else if (["PATIENT_SUMMARY", "NOTES"].includes(wakeWord))
            dispatch(setLeftConsultationTab(wakeWord));
        else if (["MEDICAL_CHECKS", "DIAGNOSES", "PROCEDURES", "METHODOLOGY", "MATERIALS", "Q_AND_A"].includes(wakeWord))
            dispatch(setRightConsultationTab(wakeWord));
    }, [wakeWord]);

    return (
        <Grid container spacing={3} style={{textAlign: "left"}}>
            <Grid item xs={4}>
                <ConsultationLeftPanel />
            </Grid>
            <Grid item xs={8}>
                <ConsultationRightPanel />
            </Grid>
        </Grid>
    )
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Patient, PatientCreationResponse} from "../../entities/types";
import {stateTransitionRequest} from "./stateMachineSlice";

interface patientSelectionState {
    patient: Patient
    finishCreation: boolean,
    startConsultation: boolean,
    form: {
        medicalConditionDialogOpen: boolean,
    }
}

const initialState: patientSelectionState = {
    patient: {
        ID: -1,
        FullName: "",
        Gender: "Male",
        MedicalConditions: [],
        IsCompleted: false,
        ThreadId: -1,
        Age: 18,
        Summary: ""
    },
    finishCreation: false,
    startConsultation: false,
    form: {
        medicalConditionDialogOpen: false,
    }
};

export const patientSelectionSlice = createSlice({
    name: 'patientSelectionSlice',
    initialState,
    reducers: {
        setPatient: (state: patientSelectionState, action: PayloadAction<Patient>) => {
            state.patient = action.payload;
        },
        setPatientCreationResponse: (state: patientSelectionState, action: PayloadAction<PatientCreationResponse>) => {
            state.patient = action.payload.patient;
            state.finishCreation = action.payload.finishCreation;
            state.startConsultation = action.payload.startConsultation;
        },
        setMedicalConditionDialogOpen: (state: patientSelectionState, action: PayloadAction<boolean>) => {
            state.form.medicalConditionDialogOpen = action.payload;
        },
        addMedicalCondition: (state: patientSelectionState, action: PayloadAction<string | undefined>) => {
            if (action.payload)
                state.patient.MedicalConditions = [...(state.patient.MedicalConditions || []), action.payload];
            state.form.medicalConditionDialogOpen = false;
        },
        removeMedicalCondition: (state: patientSelectionState, action: PayloadAction<string>) => {
            state.patient.MedicalConditions = state.patient.MedicalConditions?.filter(mc => mc !== action.payload);
        },
        patientCreationPrompt: (state: patientSelectionState, action: PayloadAction<string>) => {
            (<any>action).asyncDispatch(stateTransitionRequest({
                initialState: "PATIENT_CREATION",
                action: "CREATE_PATIENT_PROMPT",
                expectedNewState: "PATIENT_CREATION",
                requestPayload: {
                    patientId: state.patient.ID,
                    prompt: action.payload
                }
            }));
        },
    },
});

export const {
    setPatient,
    setPatientCreationResponse,
    setMedicalConditionDialogOpen,
    addMedicalCondition,
    removeMedicalCondition,
    patientCreationPrompt,

} = patientSelectionSlice.actions;

export default patientSelectionSlice.reducer;

import {setConfirmDialogBox} from "../redux/slices/confirmDialogBoxSlice";
import {stateTransitionRequest} from "../redux/slices/stateMachineSlice";
import {RecapResponsePayload} from "../entities/types";

export function dialog_finishRecap(dispatch: any, recapData?: RecapResponsePayload, emails?: string[]) {
    dispatch(setConfirmDialogBox({
        title: "Finish recap",
        content: "Are you sure you wish to finish this recap?",
        onConfirm: () => stateTransitionRequest({
            initialState: "PATIENT_SELECTION",
            action: "FINISH_RECAP",
            expectedNewState: "PATIENT_SELECTION",
            requestPayload: {
                recapId: recapData?.recap?.ID,
                emails: emails ? emails : []
            }
        })
    }));
}

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {
    Backdrop, Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Slide
} from "@mui/material";
import {TransitionProps} from '@mui/material/transitions';
import {onConfirmBooleanDialogAction} from "../redux/slices/sharedActions";
import palette from "../helpers/palette";

export default function () {
    const dispatch = useDispatch();
    const dialogBoxState = useSelector((state: RootState) => state.confirmDialogBoxSlice);
    const wakeWord = useSelector((state: RootState) => state.audioSlice.wakeWord);

    /**
     * Remove comment to put back voice commands
     */
    useEffect(() => {
        if (!dialogBoxState.isOpen || !wakeWord) return;
        else if (wakeWord === "CANCEL") onCancel();
    }, [wakeWord]);

    function onConfirm() {
        dispatch(onConfirmBooleanDialogAction(true));
    }

    function onCancel() {
        dispatch(onConfirmBooleanDialogAction(false));
    }

    return <Dialog
        open={dialogBoxState.isOpen || false}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
    >
        <DialogTitle>{dialogBoxState.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {dialogBoxState.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onConfirm} style={{color:palette.accent}}>Finish</Button>
            <Button onClick={onCancel} style={{color:palette.accent2}}>Cancel</Button>
        </DialogActions>
    </Dialog>
        ;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

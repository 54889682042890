import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {Divider, List, ListItem, ListItemText} from "@mui/material";
import EmptyCommandPlaceholder from "./EmptyCommandPlaceholder";
import {rgba} from "../../../../../helpers/palette";
import Icd10Chip from "../../../../../components/Icd10Chip";

export default function () {
    const diagnoses = useSelector((state: RootState) => state.consultationSlice.commandResponses["DIAGNOSES"]);

    if (!diagnoses) return <EmptyCommandPlaceholder/>;

    const diagnosesItems: any = diagnoses.analysis.diagnoses;

    return (
        <List style={{padding: 0}}>
            {diagnosesItems.map((diagnosis: any) => {
                const percentage = Number((diagnosis.probability * 100).toFixed(0));
                return <span key={diagnosis.name}>
                    <Divider/>
                    <ListItem
                        style={{
                            background: `linear-gradient(90deg, ${rgba.primary2} 0%, ${rgba.primary2} ${percentage}%, rgba(255,255,255,1) ${percentage + 10}%)`
                        }}
                    >
                            <ListItemText
                                primary={diagnosis.name}
                                secondary={<span><Icd10Chip icd10={diagnosis.icd10} />
                                    {"Probability: " + percentage + "%"}</span>}
                            />
                    </ListItem>
                </span>;
                }
            )}
        </List>
    )
}
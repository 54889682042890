import {
    Avatar, Button,
    CardContent, Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    styled
} from "@mui/material";
import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import React, {useEffect} from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";
import {setMachineState, stateTransitionRequest} from "../../../../redux/slices/stateMachineSlice";
import palette from "../../../../helpers/palette";

const height = "70vh";

export default function () {
    const dispatch = useDispatch();
    const patients = useSelector((state: RootState) => state.patientSelectionSlice.patients);

    function selectPatient(patientId: number) {
        dispatch(stateTransitionRequest({
            initialState: "PATIENT_SELECTION",
            action: "SELECT_PATIENT_START_CONSULTATION",
            expectedNewState: "CONSULTATION",
            requestPayload: {
                patientId
            }
        }));
    }

    return <>
        <Card style={{overflow: "auto", maxHeight: height, width: "100%"}}>
            <List>
                {
                    patients?.length
                        ? patients.map(patient => <ListItemButton key={patient.ID}
                                                                  onClick={(e) => selectPatient(patient.ID)}>
                            <ListItemAvatar>
                                <Avatar
                                    style={{backgroundColor: palette.primary}}
                                >
                                    {patient.FullName?.charAt(0)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={patient?.FullName} secondary={patient.Age}/>
                        </ListItemButton>)
                        : <div>No patients yet. Create a patient by clicking the button "Create Patient" or by saying
                            "Create".</div>
                }
            </List>
        </Card>
    </>

}

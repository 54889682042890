import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {
    Avatar, CardContent,
    CardHeader,
    Grid, List, ListItem, ListItemText
} from "@mui/material";
import EmptyCommandPlaceholder from "./EmptyCommandPlaceholder";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import Card from "@mui/material/Card";
import palette from "../../../../../helpers/palette";
import Icd10Chip from "../../../../../components/Icd10Chip";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import heights from "../../../../../helpers/heights";
import LinearProgressLabel from "../../../../../helpers/LinearProgressLabel";

export default function () {
    const procedures = useSelector((state: RootState) => state.consultationSlice.commandResponses["PROCEDURES"]);

    if (!procedures) return <EmptyCommandPlaceholder/>;

    const proceduresItem = procedures.analysis.procedures;

    return (
        <Grid container spacing={2} style={{padding: 10, backgroundColor: palette.background4, minHeight: heights.consultationPanel}}>
            {proceduresItem.map((procedure: any) =>
                <Grid key={procedure.name} item xs={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar style={{backgroundColor: palette.supporting2}}>
                                    <MedicalServicesIcon/>
                                </Avatar>
                            }
                            title={procedure.name}
                            subheader={<span>
                                <Icd10Chip icd10={procedure.diagnosis.icd10}/> {procedure.diagnosis.diagnosisName}
                            </span>
                            }
                        />
                        <LinearProgressLabel value={procedure.relevance * 10}  />
                        <CardContent>
                            <List style={{padding: 0}}>
                                {
                                    procedure.advantages.map((advantage: any) =>
                                        <ListItem key={advantage} style={{padding: 0}}>
                                            <AddCircleIcon fontSize="small" style={{color: palette.accent}}/>
                                            <ListItemText key={advantage} primary={advantage} style={{marginRight: 5}}/>
                                        </ListItem>
                                    )
                                }
                                {
                                    procedure.disadvantages.map((disadvantage: any) =>
                                        <ListItem key={disadvantage} style={{padding: 0}}>
                                            <RemoveCircleIcon fontSize="small" style={{color: palette.accent3}}/>
                                            <ListItemText primary={disadvantage} style={{marginRight: 5}}/>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}
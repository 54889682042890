import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Backdrop, Button, Divider, Grid} from "@mui/material";
import SelectPatientCard from "./components/SelectPatientCard";
import {RootState} from "../../../redux/store";
import {setMachineState, stateTransitionRequest} from "../../../redux/slices/stateMachineSlice";
import {
    createNewPatient,
    patientSelectionResumeConsultation,
    patientSelectionResumeRecap
} from "../../../helpers/patientSelectionHelper";
import PropagateLoader from "react-spinners/PropagateLoader";
import palette from "../../../helpers/palette";

export default function () {
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => state.stateMachineSlice.response);
    const wakeWord = useSelector((state: RootState) => state.audioSlice.wakeWord);
    const isAwaitingResponse = useSelector((state: RootState) => state.stateMachineSlice.isAwaitingResponse);

    useEffect(() => {
        if (!response) return;
        if (response.request.initialState === "PATIENT_SELECTION")
            switch (response.effectiveNewState) {
                case "PATIENT_CREATION":
                    dispatch(setMachineState("PATIENT_CREATION"));
                    break;
                case "CONSULTATION": // todo: maybe dialog box if the action is SELECT_PATIENT_START_CONSULTATION ?
                    if (response.request.action === "SELECT_PATIENT_START_CONSULTATION")
                        dispatch(setMachineState("CONSULTATION"));
                    else if (response.request.action === "GET_RESUME_DATA")
                        patientSelectionResumeConsultation(dispatch);
                    break;
                case "RECAP":
                    patientSelectionResumeRecap(dispatch);
                    break;
            }
        else switch (response.effectiveNewState) {
            case "CONSULTATION":
                dispatch(setMachineState("CONSULTATION"));
                break;
            case "RECAP":
                dispatch(setMachineState("RECAP"));
                break;
        }
    }, [response]);

    useEffect(() => {
        if (wakeWord === "CREATE")
            createNewPatient(dispatch);
    }, [wakeWord]);

    return (
        <Grid container spacing={5}>
            <Grid item xs={2}/>
            <Grid item xs={8} style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <SelectPatientCard/>
            </Grid>
            <Grid item xs={2}/>
        </Grid>
    )
}
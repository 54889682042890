import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import EmptyCommandPlaceholder from "./EmptyCommandPlaceholder";
import VaccinesIcon from '@mui/icons-material/Vaccines';
import palette from "../../../../../helpers/palette";

export default function () {
    const medicalChecks = useSelector((state: RootState) => state.consultationSlice.commandResponses["MEDICAL_CHECKS"]);

    if (!medicalChecks) return <EmptyCommandPlaceholder/>;

    const medicalChecksSuggestions = medicalChecks.analysis.medicalChecksSuggestions;

    return (
        <List style={{padding: 0}}>
            {medicalChecksSuggestions.map((suggestion: string) =>
                <span key={suggestion}>
                    <Divider/>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar style={{backgroundColor: palette.supporting2}}>
                                <VaccinesIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={suggestion}/>
                    </ListItem>
                </span>
            )}
        </List>
    )
}
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import palette from "../../../helpers/palette";
import heights from "../../../helpers/heights";
import {
    Avatar,
    Button,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText, Typography
} from "@mui/material";
import Card from "@mui/material/Card";
import fonts from "../../../helpers/fonts";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {addEmail, removeEmail, setEmailsDialogOpen} from "../../../redux/slices/recapSlice";
import {setTextDialogBox} from "../../../redux/slices/textDialogBoxSlice";

export default function () {
    const dispatch = useDispatch();
    const recapData: any = useSelector((state: RootState) => state.recapSlice.recapData);
    const emails: any = useSelector((state: RootState) => state.recapSlice.emails);
    const recapParsed = JSON.parse(recapData.recap.Content);

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const day = date.toLocaleString('en-US', { day: '2-digit' });
        const month = date.toLocaleString('en-US', { month: 'long' });
        const year = date.toLocaleString('en-US', { year: 'numeric' });
        const time = date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
        return `${day} ${month} ${year} at ${time}`;
    }

    return (
        <Grid container
              style={{overflow: "auto", height: "70vh", justifyContent: "center"}}>
            <Grid item xs={6}>
                <Card>
                    <CardHeader
                        avatar={
                            <Avatar style={{backgroundColor: palette.supporting3}}>
                                {recapParsed.patient.FullName?.charAt(0)}
                            </Avatar>
                        }
                        title="Consultation Recap for patient"
                        subheader={recapParsed.patient.FullName}
                        titleTypographyProps={{style: {fontFamily: fonts.secondary, fontSize: 20}}}
                        subheaderTypographyProps={{style: {fontFamily: fonts.secondary, fontSize: 18}}}
                    />
                    <Divider/>
                    <CardContent style={{textAlign: "left", paddingLeft: 20}}>
                        <Typography
                            style={{fontFamily: fonts.secondary, fontSize: 18, marginBottom: 5}}
                        >
                            Patient Info
                        </Typography>
                        <Typography
                            style={{color: palette.background3, paddingLeft: 10, fontSize: 16}}
                        >
                            <strong>Gender: </strong> {recapParsed.patient.Gender}
                            <br/>
                            <strong>Age: </strong> {recapParsed.patient.Age}
                            <br/>
                            <strong>Medical Conditions: </strong>
                            {recapParsed.patient.MedicalConditions && recapParsed.patient.MedicalConditions.length > 0 ? recapParsed.patient.MedicalConditions.join(', ') : 'N/A'}
                            <br/>
                        </Typography>
                        <Typography
                            style={{fontFamily: fonts.secondary, fontSize: 18, marginBottom: 5, marginTop: 10}}
                        >
                            Consultation Info
                        </Typography>
                        <Typography
                            style={{color: palette.background3, paddingLeft: 10, fontSize: 16}}
                        >
                            <strong>Consultation Date and
                                Time: </strong> {formatDate(recapParsed.consultation.Start)}
                            <br/>
                            <strong>Reason for
                                Consultation: </strong> {recapParsed.reasonForConsultation}
                            <br/>
                            <strong>Summary of
                                Consultation: </strong> {recapParsed.reasonForConsultation}
                            <br/>
                            <strong>Medications and Recommendations: </strong>
                        </Typography>
                        <ul>
                            {recapParsed.medicationsAndRecommendations.length > 0 ? (
                                recapParsed.medicationsAndRecommendations.map((recommendation: string) => (
                                    <li key={recommendation}>{recommendation}</li>
                                ))
                            ) : (
                                <li>N/A</li>
                            )}
                        </ul>
                        <Typography
                            style={{fontFamily: fonts.secondary, fontSize: 18, marginBottom: 5, marginTop: 10}}
                        >
                            Next Consultation
                        </Typography>
                        <Typography
                            style={{color: palette.background3, paddingLeft: 10, fontSize: 16}}
                        >
                            {recapParsed.nextConsultation ? recapParsed.nextConsultation : "N/A"}
                        </Typography>
                        <br/>
                        <Divider/>
                        <Typography
                            style={{fontFamily: fonts.secondary, fontSize: 18, marginBottom: 5, marginTop: 10}}
                        >
                            Send Recap to
                        </Typography>
                        <Typography>
                            {emails?.map((email: string) =>
                                <Chip label={email} key={email} component={"span"}
                                      style={{marginRight: 10, marginBottom: 10}}
                                      onClick={() => dispatch(removeEmail(email))}
                                      onDelete={() => dispatch(removeEmail(email))}
                                />
                            )}
                            <Button variant="outlined"
                                    style={{marginLeft: 10, borderRadius: 1000, marginBottom: 10}}
                                    color="success"
                                    startIcon={<AddCircleIcon/>}
                                    onClick={() => dispatch(setTextDialogBox({
                                        title: "Add email",
                                        content: "",
                                        onConfirm: (email: string) => addEmail(email)
                                    }))}
                            >
                                Add Email
                            </Button>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

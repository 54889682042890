import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MACHINE_STATE, MachineRequest, MachineResponse, WakeWord, WakeWordResponse} from "../../entities/types";
import {consultationPrompt, pushConsultationResponse, resetConsultation, resumeConsultation} from "./consultationSlice";
import {setPatients} from "./patientSelectionSlice";
import {patientCreationPrompt, setPatientCreationResponse} from "./patientCreationSlice";
import {WS_SOCKET_URL} from "../../config/config";
import {handleRecapResponse, recapEditPrompt} from "./recapSlice";
import {clearWakeWords} from "./audioSlice";

interface socketState {
    socket?: WebSocket;
    isSocketConnected: boolean;
    isAwaitingResponse: boolean;
    isAwaitingAudio: boolean;

    machineState: MACHINE_STATE;
    request?: MachineRequest;
    response?: MachineResponse;
}

const initialState: socketState = {
    isSocketConnected: false,
    isAwaitingResponse: false,
    isAwaitingAudio: false,
    machineState: "PATIENT_SELECTION" // default should be : "PATIENT_SELECTION", all other changes are for testing only
};

let retryTimeout: any = null;
const retryDelay = 5000;

export const stateMachineSlice = createSlice({
    name: 'stateMachineSlice',
    initialState,
    reducers: {
        setupAndConnect: (state: socketState, action: PayloadAction<any>) => {
            if (state.socket)
                state.socket.close();
            createSocket(state, action);
        },
        setConnected: (state: socketState, action: PayloadAction<boolean>) => {
            state.isSocketConnected = action.payload;
        },
        stateTransitionRequest: (state: socketState, action: PayloadAction<MachineRequest>) => {
            if (!state.socket || state.socket.readyState !== WebSocket.OPEN) {
                console.log("Cannot send, state socket is: ");
                console.log(state.socket);
                return;
            }
            console.log("Sending message with action: " + action.payload.action);
            console.log(action.payload);
            state.request = action.payload;
            state.isAwaitingResponse = true;
            state.socket.send(action.payload.action + "\n" + JSON.stringify(action.payload));
            (<any>action).asyncDispatch(clearWakeWords({}));
        },
        statePrompt: (state: socketState, action: PayloadAction<string>) => {
            console.log("PROMPTing: " + action.payload);
            makePrompt(state.machineState, action);
        },
        onMessage: (state: socketState, action: PayloadAction<string>) => {
            console.log("Received message: ");
            const message: MachineResponse = JSON.parse(action.payload);
            console.log(message);
            state.isAwaitingResponse = false;
            state.response = message;
            state.machineState = message.request.initialState;
            handleMessage(message, action);
        },
        setMachineState: (state: socketState, action: PayloadAction<MACHINE_STATE>) => {
            console.log("Setting machine state: " + action.payload);
            state.machineState = action.payload;
            (<any>action).asyncDispatch(clearWakeWords({}));
        },
    },
});

export const {
    setupAndConnect,
    stateTransitionRequest,
    onMessage,
    setMachineState,
    setConnected,
    statePrompt,
} = stateMachineSlice.actions;

export default stateMachineSlice.reducer;

function createSocket(state: socketState, action: any) {
    state.socket = new WebSocket(WS_SOCKET_URL);
    state.socket.onopen = () => {
        console.log('WebSocket connected');
        action.asyncDispatch(setConnected(true));
        if (retryTimeout) {
            clearTimeout(retryTimeout);
            retryTimeout = null;
        }
    };
    state.socket.onmessage = (event: MessageEvent<any>) => {
        if (event.data === "ping") return;
        action.asyncDispatch(onMessage(event.data));
    };
    state.socket.onclose = (event: any) => {
        console.log('WebSocket disconnected:');
        console.log(event.reason);
        action.asyncDispatch(setConnected(false));
        retryTimeout = window.setTimeout(() => {
            action.asyncDispatch(setupAndConnect({}));
        }, retryDelay);
    };
    state.socket.onerror = (error: any) => {
        console.error('WebSocket error:');
        console.error(JSON.stringify(error));
        action.asyncDispatch(setConnected(false));
    };
}

function handleMessage(message: MachineResponse, action: PayloadAction<any>) {
    switch (message.request.action) {
        case "GET_RESUME_DATA":
            (<any>action).asyncDispatch(setPatients(message.responsePayload.allPatients));
            break;
        case "CREATE_PATIENT":
            (<any>action).asyncDispatch(setPatientCreationResponse(message.responsePayload));
            break;
        case "CREATE_PATIENT_PROMPT":
            (<any>action).asyncDispatch(setPatientCreationResponse(message.responsePayload));
            break;
        case "CONFIRM_CREATE_PATIENT":
            (<any>action).asyncDispatch(setPatients(message.responsePayload.allPatients));
            break;
        case "RESUME_CONSULTATION":
            (<any>action).asyncDispatch(resumeConsultation(message.responsePayload));
            break;
        case "SELECT_PATIENT_START_CONSULTATION":
            (<any>action).asyncDispatch(resetConsultation(message.responsePayload));
            break;
        case "CONFIRM_CREATE_PATIENT_START_CONSULTATION":
            break;
        case "CONSULTATION_PROMPT":
            (<any>action).asyncDispatch(pushConsultationResponse(message));
            break;
        case "RESUME_RECAP":
        case "FINISH_CONSULTATION_START_RECAP":
        case "RECAP_EDIT_PROMPT":
            (<any>action).asyncDispatch(handleRecapResponse({RecapResponsePayload: message.responsePayload, machineAction: message.request.action }));
            break;
        case "FINISH_RECAP":
            (<any>action).asyncDispatch(setPatients(message.responsePayload.allPatients));
            break;
        case "TEST":
            console.log("Test!");
            console.log(message);
    }
    // (<any>action).asyncDispatch(resetConsultation({}));
}

function makePrompt(machineState: MACHINE_STATE, action: PayloadAction<any>) {
    switch (machineState) {
        case "PATIENT_CREATION":
            (<any>action).asyncDispatch(patientCreationPrompt(action.payload));
            break;
        case "CONSULTATION":
            (<any>action).asyncDispatch(consultationPrompt(action.payload));
            break;
        case "RECAP":
            (<any>action).asyncDispatch(recapEditPrompt(action.payload));
            break;
        default:
            console.error("Prompt unsupported for state: " + machineState);
    }
}

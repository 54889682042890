import React, {useEffect} from "react";
import {Grid} from "@mui/material";
import CreatePatientCard from "./components/CreatePatientCard";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {setMachineState, stateTransitionRequest} from "../../../redux/slices/stateMachineSlice";
import {
    cancelCreatePatient,
    dialog_confirmCreatePatient,
    dialog_confirmCreatePatientStartConsultation,
    isValidPatient
} from "../../../helpers/patientCreationHelper";

export default function () {
    const dispatch = useDispatch();
    const response = useSelector((state: RootState) => state.stateMachineSlice.response);
    const patient = useSelector((state: RootState) => state.patientCreationSlice.patient);
    const wakeWord = useSelector((state: RootState) => state.audioSlice.wakeWord);
    const isDialogOpen = useSelector((state: RootState) => state.confirmDialogBoxSlice.isOpen);

    useEffect(() => {
        if (!response) return;
        if (response.request.initialState === "PATIENT_CREATION")
            switch (response.effectiveNewState) {
                case "PATIENT_SELECTION":
                    dialog_confirmCreatePatient(dispatch, patient);
                    break;
                case "CONSULTATION":
                    dialog_confirmCreatePatientStartConsultation(dispatch, patient);
                    break;
            }
        else switch (response.effectiveNewState) {
            case "PATIENT_SELECTION":
                dispatch(setMachineState("PATIENT_SELECTION"));
                break;
            case "CONSULTATION":
                dispatch(setMachineState("CONSULTATION"));
                break;
        }
    }, [response]);

    useEffect(() => {
        if (isDialogOpen) return;
        else if (wakeWord === "CANCEL")
            cancelCreatePatient(dispatch);
        else if (wakeWord === "FINISH")
            dialog_confirmCreatePatient(dispatch, patient);
    }, [wakeWord]);

    return (
        <Grid container spacing={5}>
            <Grid item xs={2}/>
            <Grid item xs={8} style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <CreatePatientCard/>
            </Grid>
            <Grid item xs={2}/>
        </Grid>
    )
}

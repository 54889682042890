import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Button, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import FastForwardIcon from '@mui/icons-material/FastForward';
import ReceiptIcon from "@mui/icons-material/Receipt";
import {dialog_finishRecap} from "../../../helpers/recapHelper";
import {RootState} from "../../../redux/store";
import {dialog_finishConsultationStartRecap} from "../../../helpers/consultationHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import palette, {rgba} from "../../../helpers/palette";
import fonts from "../../../helpers/fonts";
export default function () {
    const dispatch = useDispatch();
    const recapData = useSelector((state: RootState) => state.recapSlice.recapData);
    const emails = useSelector((state: RootState) => state.recapSlice.emails);
    const recapParsed = (recapData && recapData.recap.Content) ? JSON.parse(recapData.recap.Content) : undefined;

    return (
        <Grid container style={{
            height: "10vh",
            background: "linear-gradient(45deg, " + rgba.supporting3 + " 0%, " + rgba.primary + " 20%)",
        }}>
            <Grid item xs={8}>
                <div>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                width: 40,
                                height: 40,
                                margin: 20,
                                color: palette.supporting3,
                                backgroundColor: palette.background
                            }}>
                                <ReceiptIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Recap"
                            secondary={"of consultation with patient " + (recapParsed ? recapParsed.patient.FullName : "...")}
                            primaryTypographyProps={{fontSize: 25, fontFamily: fonts.secondary, color: palette.background3}}
                            secondaryTypographyProps={{fontSize: 18, fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </div>
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={3} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Card style={{borderRadius: 999, paddingRight: 20}} onClick={() => dialog_finishRecap(dispatch, recapData, emails)}>
                    <ListItem style={{padding: 0}}>
                        <ListItemAvatar>
                            <Avatar style={{
                                height: 40,
                                color: palette.accent,
                                backgroundColor: palette.background
                            }}>
                                <CheckCircleIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Finish recap"
                            primaryTypographyProps={{fontFamily: fonts.secondary, color: palette.background3}}
                        />
                    </ListItem>
                </Card>
            </Grid>
        </Grid>
    )
        ;
}

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {
    Backdrop, Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Slide, TextField
} from "@mui/material";
import {TransitionProps} from '@mui/material/transitions';
import {onConfirmBooleanDialogAction, onConfirmTextDialogAction} from "../redux/slices/sharedActions";
import palette from "../helpers/palette";
import {setTextDialogBoxContent} from "../redux/slices/textDialogBoxSlice";

export default function () {
    const dispatch = useDispatch();
    const dialogBoxState = useSelector((state: RootState) => state.textDialogBoxSlice);

    function onConfirm() {
        dispatch(onConfirmTextDialogAction(dialogBoxState.content));
    }

    function onCancel() {
        dispatch(onConfirmTextDialogAction());
    }

    return <Dialog
        open={dialogBoxState.isOpen || false}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
        fullWidth
    >
        <DialogTitle>{dialogBoxState.title}</DialogTitle>
        <DialogContent>
            <TextField
                multiline={dialogBoxState.lines ? (dialogBoxState.lines > 1) : false}
                rows={dialogBoxState.lines ? dialogBoxState.lines : 1}
                fullWidth
                value={dialogBoxState.content}
                onChange={e => dispatch(setTextDialogBoxContent(e.target.value))}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onConfirm} style={{color: palette.accent}}>Confirm</Button>
            <Button onClick={onCancel} style={{color: palette.accent2}}>Cancel</Button>
        </DialogActions>
    </Dialog>
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

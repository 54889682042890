import React from "react";
import {useDispatch, useSelector} from "react-redux";

export default function () {
    const dispatch = useDispatch();

    return (
        <>
            ERROR
        </>
    )
}
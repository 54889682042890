import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    MACHINE_STATE,
    SpeakerEvent,
    STOCK_SOUND, TranscribeResponseType, TRANSCRIPTION_STATUS,
    TranscriptionResponse,
    WakeWord,
    WakeWordResponse
} from "../../entities/types";
import {setupAndConnect, statePrompt} from "./stateMachineSlice";
import {WS_SPEAKER_URL, WS_TRANSCRIBE_URL} from "../../config/config";
import {onConfirmBooleanDialogAction} from "./sharedActions";

let speakerRetryTimeout: any = null;
let transcribeRetryTimeout: any = null;
const retryDelay = 5000;

interface audioState {
    isTranscribeSocketConnected: boolean,
    isTranscribeRecording: boolean,
    transcribeSocket?: WebSocket,
    transcriptionResponse?: TranscriptionResponse,
    wakeWordResponse?: WakeWordResponse,
    wakeWord?: WakeWord,

    isSpeakerSocketConnected: boolean,
    isAwaitingSpeaker: boolean,
    isSpeakerPlaying: boolean,
    speakerSocket?: WebSocket,
    speakerAudio?: any, // HTMLAudioElement

    stockSound?: STOCK_SOUND,

    isSpeakerMuted: boolean,
    isTranscribeMuted: boolean,

    machineStateForwarded: MACHINE_STATE, // forwarded from stateMachineSlice
    isAwaitingResponseForwarded: boolean, // forwarded from stateMachineSlice
}

const initialState: audioState = {
    isTranscribeSocketConnected: false,
    isTranscribeRecording: false,

    isSpeakerSocketConnected: false,
    isAwaitingSpeaker: false,
    isSpeakerPlaying: false,

    isSpeakerMuted: window.localStorage.getItem("isSpeakerMuted") === "true",
    isTranscribeMuted: window.localStorage.getItem("isTranscribeMuted") === "true",

    machineStateForwarded: "PATIENT_SELECTION",
    isAwaitingResponseForwarded: false,
};

export const audioSlice = createSlice({
    name: 'audioSlice',
    initialState,
    reducers: {
        setupAndConnectSpeaker: (state: audioState, action: PayloadAction<any>) => {
            if (state.speakerSocket)
                state.speakerSocket.close();
            createSpeakerSocket(state, action);
        },
        setSpeakerConnected: (state: audioState, action: PayloadAction<boolean>) => {
            state.isSpeakerSocketConnected = action.payload;
            state.isTranscribeRecording = false;
            state.isAwaitingSpeaker = false;
        },
        onSpeakerEvent: (state: audioState, action: PayloadAction<SpeakerEvent>) => {
            state.isAwaitingSpeaker = action.payload.type === "PREPARING";
        },
        startSpeaker: (state: audioState, action: PayloadAction<HTMLAudioElement>) => {
            if (state.isSpeakerMuted) return;
            if (state.isSpeakerPlaying)
                return console.warn("Speaker was already playing when asked to play again!!");
            state.speakerAudio = action.payload;
            state.isSpeakerPlaying = true;
            action.payload.addEventListener('ended', () => {
                (<any>action).asyncDispatch(stopSpeaker({}));
            });
            action.payload.play();
        },
        stopSpeaker: (state: audioState, action: PayloadAction<any>) => {
            state.isSpeakerPlaying = false;
            if (!state.speakerAudio) return;
            state.speakerAudio.pause();
        },
        setupAndConnectTranscribe: (state: audioState, action: PayloadAction<any>) => {
            if (state.transcribeSocket)
                state.transcribeSocket.close();
            createTranscribeSocket(state, action);
        },
        setTranscribeConnected: (state: audioState, action: PayloadAction<boolean>) => {
            state.isTranscribeSocketConnected = action.payload;
        },
        startTranscription: (state: audioState, action: PayloadAction<{}>) => {
            if (state.machineStateForwarded === "PATIENT_SELECTION" || state.isTranscribeRecording) return;
            console.log("STARTING TRANSCRIBE !");
            state.transcribeSocket?.send(JSON.stringify({type: "START"}));
            state.stockSound = "TRANSCRIBE_START";
            state.isTranscribeRecording = true;
        },
        stopTranscription: (state: audioState, action: PayloadAction<TranscriptionResponse>) => {
            console.log("STOPPING TRANSCRIBE !")
            state.isTranscribeRecording = false;
            console.log(action.payload)
            if (action.payload.status === "STOP") {
                state.stockSound = "TRANSCRIBE_END";
                (<any>action).asyncDispatch(statePrompt(action.payload.transcription));
                // dispatch prompt
            } else if (action.payload.status === "NO_OP") {
                state.stockSound = "TRANSCRIBE_NO_OP";
            } else {
                state.stockSound = "TRANSCRIBE_ERROR";
                // show error somewhere?
            }
        },
        forceStopTranscription: (state: audioState, action: PayloadAction<{}>) => {
            state.transcribeSocket?.send(JSON.stringify({type: "FORCE_STOP"}));
        },
        forceCancelTranscription: (state: audioState, action: PayloadAction<{}>) => {
            state.transcribeSocket?.send(JSON.stringify({type: "FORCE_CANCEL"}));
        },
        onTranscribeMessage: (state: audioState, action: PayloadAction<string>) => {
            const response: any = JSON.parse(action.payload);
            if (response.type === "TRANSCRIPTION") {
                const transcriptionResponse: TranscriptionResponse = response;
                state.transcriptionResponse = transcriptionResponse;
                switch (transcriptionResponse.status) {
                    case "START":
                        (<any>action).asyncDispatch(startTranscription({}));
                        break;
                    case "IN_PROGRESS":
                        break;
                    case "STOP":
                    case "NO_OP":
                    case "ERROR":
                        (<any>action).asyncDispatch(stopTranscription(transcriptionResponse));
                        break;
                }
            } else if (response.type === "WAKE_WORD") {
                if (state.isAwaitingResponseForwarded || state.isAwaitingSpeaker || state.isSpeakerPlaying || state.isTranscribeRecording) {
                    state.wakeWordResponse = undefined;
                    state.wakeWord = undefined;
                    return;
                }
                const wakeWordResponse: WakeWordResponse = response;
                handleWakeWords(state, action, wakeWordResponse);
            }
        },
        sendTranscribeChunk: (state: audioState, action: PayloadAction<any>) => {
            if (!state.transcribeSocket || state.transcribeSocket.readyState !== WebSocket.OPEN
                || state.isAwaitingResponseForwarded
                || state.isAwaitingSpeaker || state.isSpeakerPlaying)
                return;
            state.transcribeSocket.send(action.payload);
        },
        clearWakeWords: (state: audioState, action: PayloadAction<any>) => {
            state.wakeWordResponse = undefined;
            state.wakeWord = undefined;
        },
        forwardMachineState: (state: audioState, action: PayloadAction<MACHINE_STATE>) => {
            state.machineStateForwarded = action.payload;
            state.transcriptionResponse = undefined;
        },
        forwardIsAwaitingResponse: (state: audioState, action: PayloadAction<boolean>) => {
            state.isAwaitingResponseForwarded = action.payload;
        },
        setIsSpeakerMuted: (state: audioState, action: PayloadAction<boolean>) => {
            state.isSpeakerMuted = action.payload;
            window.localStorage.setItem("isSpeakerMuted", action.payload.toString());
        },
        setIsTranscribeMuted: (state: audioState, action: PayloadAction<boolean>) => {
            state.isTranscribeMuted = action.payload;
            window.localStorage.setItem("isTranscribeMuted", action.payload.toString());
        },
    },
    extraReducers: builder => {
        builder.addCase(onConfirmBooleanDialogAction, (state, action) => {
            state.wakeWordResponse = undefined;
            state.wakeWord = undefined;
        });
    },
});

export const {
    setupAndConnectSpeaker,
    setSpeakerConnected,
    onSpeakerEvent,
    startSpeaker,
    stopSpeaker,

    setupAndConnectTranscribe,
    setTranscribeConnected,
    onTranscribeMessage,
    sendTranscribeChunk,

    startTranscription,
    stopTranscription,
    forceStopTranscription,
    forceCancelTranscription,

    clearWakeWords,
    forwardIsAwaitingResponse,
    forwardMachineState,
    setIsSpeakerMuted,
    setIsTranscribeMuted
} = audioSlice.actions;

export default audioSlice.reducer;

function createSpeakerSocket(state: audioState, action: any) {
    state.speakerSocket = new WebSocket(WS_SPEAKER_URL);
    state.speakerSocket.onopen = () => {
        console.log('Speaker WebSocket connected');
        action.asyncDispatch(setSpeakerConnected(true));
        if (speakerRetryTimeout) {
            clearTimeout(speakerRetryTimeout);
            speakerRetryTimeout = null;
        }
    };
    state.speakerSocket.onmessage = (event: MessageEvent<any>) => {
        const data = event.data;
        if (typeof data === "string") {
            if (event.data === "ping") return;
            const speakerEvent = JSON.parse(data);
            action.asyncDispatch(onSpeakerEvent(speakerEvent));
        } else {
            const audioUrl = URL.createObjectURL(new Blob([data], {type: 'audio/mp3'}));
            const audio = new Audio(audioUrl);
            action.asyncDispatch(startSpeaker(audio));
        }
    };
    state.speakerSocket.onclose = (event: any) => {
        console.log('Speaker WebSocket disconnected:');
        console.log(event.reason);
        action.asyncDispatch(setSpeakerConnected(false));
        speakerRetryTimeout = window.setTimeout(() => {
            action.asyncDispatch(setupAndConnectSpeaker({}));
        }, retryDelay);
    };
    state.speakerSocket.onerror = (error: any) => {
        console.error('Speaker WebSocket error:');
        console.error(error);
        action.asyncDispatch(setSpeakerConnected(false));
    };
}

function createTranscribeSocket(state: audioState, action: any) {
    state.transcribeSocket = new WebSocket(WS_TRANSCRIBE_URL);
    state.transcribeSocket.onopen = () => {
        console.log('Transcribe WebSocket connected');
        action.asyncDispatch(setTranscribeConnected(true));
        if (transcribeRetryTimeout) {
            clearTimeout(transcribeRetryTimeout);
            transcribeRetryTimeout = null;
        }
    };
    state.transcribeSocket.onmessage = (event: MessageEvent<any>) => {
        if (event.data === "ping") return;
        console.log("Transcribe message in!")
        action.asyncDispatch(onTranscribeMessage(event.data));
    };
    state.transcribeSocket.onclose = (event: any) => {
        console.log('Transcribe WebSocket disconnected:');
        console.log(event.reason);
        action.asyncDispatch(setTranscribeConnected(false));
        transcribeRetryTimeout = window.setTimeout(() => {
            action.asyncDispatch(setupAndConnectTranscribe({}));
        }, retryDelay);
    };
    state.transcribeSocket.onerror = (error: any) => {
        console.error('Transcribe WebSocket error:');
        console.error(error);
        action.asyncDispatch(setTranscribeConnected(false));
    };
}

function handleWakeWords(state: audioState, action: any, wakeWordResponse: WakeWordResponse) {
    const words = wakeWordResponse.detectedWakeWords;
    const isCancel = words.includes("CANCEL");
    state.wakeWordResponse = wakeWordResponse;

    if (!words.length) return state.wakeWord = undefined

    const wakeWord = isCancel ? "CANCEL" : words[0];
    state.wakeWord = wakeWord;

    if (wakeWord === "CHERRY")
        action.asyncDispatch(startTranscription({}));
}

import {
    Avatar, Button,
    CardContent, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText, Radio, RadioGroup,
    styled, Switch, TextField
} from "@mui/material";
import Card from '@mui/material/Card';
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {useDispatch, useSelector} from "react-redux";

import NotStartedIcon from '@mui/icons-material/NotStarted';
import {RootState} from "../../../../redux/store";
import {setMachineState, statePrompt, stateTransitionRequest} from "../../../../redux/slices/stateMachineSlice";
import {
    addMedicalCondition,
    removeMedicalCondition,
    setMedicalConditionDialogOpen, setPatient,
    setPatientCreationResponse
} from "../../../../redux/slices/patientCreationSlice";
import MedicalConditionDialog from "./MedicalConditionDialog";
import palette from "../../../../helpers/palette";
import {setTextDialogBox} from "../../../../redux/slices/textDialogBoxSlice";

const height = "50vh";

export default function () {
    const dispatch = useDispatch();
    const patient = useSelector((state: RootState) => state.patientCreationSlice.patient);

    return <>
        <Card style={{overflow: "auto", height, width: "100%"}}>
            <CardContent>
                <FormControl fullWidth style={{padding: 10}}>
                    <TextField fullWidth label="Full Name" value={patient.FullName || ""}
                               onChange={(e) => dispatch(setPatient({...patient, FullName: e.target.value}))}/>
                    <br/>
                    <FormGroup style={{display: "inline"}} onClick={() => dispatch(setPatient({
                        ...patient,
                        Gender: patient.Gender === "Male" ? "Female" : "Male"
                    }))}>
                        Male
                        <FormControlLabel control={<Switch checked={patient.Gender === "Female"} color="secondary"/>} label="Female"
                                          style={{marginLeft: 8}}/>
                    </FormGroup>
                    <br/>
                    <TextField fullWidth label="Age" type="number" value={patient.Age || ""}
                               onChange={(e) => dispatch(setPatient({...patient, Age: Number(e.target.value)}))}/>
                    <br/>
                    Medical Conditions
                    <br/>
                    <br/>
                    <div style={{textAlign: "left"}}>
                        {patient?.MedicalConditions?.map(medicalCondition =>
                            <Chip label={medicalCondition} key={medicalCondition}
                                  style={{marginRight: 10, marginBottom: 10}}
                                  onClick={() => dispatch(removeMedicalCondition(medicalCondition))}
                                  onDelete={() => dispatch(removeMedicalCondition(medicalCondition))}
                            />
                        )}
                        <Button variant="outlined" style={{marginLeft: 10, borderRadius: 1000, marginBottom: 10}}
                                color="secondary"
                                startIcon={<AddCircleIcon/>}
                                onClick={() => dispatch(setTextDialogBox({
                                    isOpen: true,
                                    title: "Add a medical condition",
                                    content: "",
                                    onConfirm: (medicalCondition: string) => addMedicalCondition(medicalCondition)
                                }))}>
                            Add Medical Condition
                        </Button>
                    </div>
                </FormControl>
            </CardContent>
        </Card>
    </>

}
